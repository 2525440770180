import { Box, Typography } from "@mui/material";
import ContentContainer from "../../components/GPTChat/content-container";
import { FC, useEffect, useState } from "react";
import ContentEdit from "../../components/GPTChat/content-edit";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAllFavGpts,
  getGptDetails,
  showGPTDetails,
  updateBotChatStreaming,
} from "../../store/bot/actions";
import ReportGpt from "../../components/Header/GptMenu/ReportGpt";
import ShowGptDetails from "./ShowGptDetails";
import ReviewGpt from "../../components/Header/GptMenu/ReviewGpt";
import { Dialog, DialogBody } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface ChatGptsrProps {
}
const ChatGpts: FC<ChatGptsrProps> = () => {
  const { uniqueName } = useParams();
  const { favGpts } = useSelector((state: any) => state.bot);
  const dispatch = useDispatch();
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [isMaximizedChat, setIsMaximizedChat] = useState(false);
  const { botDetails, isShowingGPT } = useSelector((state: any) => state.bot);

  const toogleOpen = () => {
    isShowingGPT
      ? dispatch(showGPTDetails(false))
      : dispatch(showGPTDetails(true));
  };

  const handleStopStreaming = () => {
    dispatch(updateBotChatStreaming(false));
  };
  useEffect(() => {
    dispatch(getGptDetails(uniqueName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueName]);

  useEffect(() => {
    !favGpts.length && dispatch(getAllFavGpts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-230px)]  md:max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-205px)] xl:max-h-[calc(100vh-205px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <ContentContainer
          showSuggestions={showSuggestions}
        />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          minHeight: "90px",
          width: "-webkit-fill-available",
          paddingRight: "40px",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box sx={{ padding: "0px" }}>
          <div className="flex flex-row justify-center items-center">
            <Typography
              variant="body2"
              sx={{
                width: "100%",
                textAlign: "center",
                display: { xs: "block", lg: "none" },
              }}
            >
              {botDetails?.specialInstruction
                ? parse(botDetails.specialInstruction)
                : ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#8F92A1",
                textAlign: "center",
                display: { xs: "none", lg: "block" },
              }}
            >
              {botDetails?.specialInstruction
                ? parse(botDetails.specialInstruction)
                : ""}
            </Typography>
          </div>
        </Box>
        <Box>
          <ContentEdit
            isMaximizedChat={isMaximizedChat}
            setIsMaximizedChat={setIsMaximizedChat}
            handleStopStreaming={handleStopStreaming}
            setShowSuggestions={setShowSuggestions}
          />
        </Box>
      </Box>
      <ReportGpt />
      <ShowGptDetails
        toogleOpen={toogleOpen}
        gptUniqueName={botDetails?.uniqueName}
      />
      <ReviewGpt />
      <Dialog
        size="xl"
        open={isMaximizedChat}
        handler={() => {
          setIsMaximizedChat(!isMaximizedChat);
        }}
      >
        <DialogBody>
          <span className="flex justify-end items-center mb-2 cursor-pointer">
            <XMarkIcon
              className="w-5 h-5 "
              onClick={() => {
                setIsMaximizedChat(!isMaximizedChat);
              }}
            />
          </span>
          <ContentEdit
            isMaximizedChat={isMaximizedChat}
            setIsMaximizedChat={setIsMaximizedChat}
            handleStopStreaming={handleStopStreaming}
            setShowSuggestions={setShowSuggestions}
          />
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ChatGpts;
