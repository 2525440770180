import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import http from "../../services/http-common";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveTokenSummary } from "../../store/dataFlow/actions";

const NumberOfToken = () => {
  const dispatch = useDispatch();
  const dispatchHttp = useMainController();
  const { tokensSummary } = useSelector((state) => state.dataFlow);

  const getTokenSummary = useMutation(
    () => {
      return http.get(
        "tokens/tokenSummary",
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        dispatch(saveTokenSummary(data));
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    !tokensSummary && getTokenSummary.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <span className="text-xs text-muted text-[#9da9b9]">
      Tokens:
      <span className=" font-bold ">
        {tokensSummary ? tokensSummary.curentBalance?.toLocaleString() : 0}
      </span>
    </span>
  );
};

export default NumberOfToken;
