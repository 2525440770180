import { useRef, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

// ** Import Type
import { FC } from "react";
import { Message } from "../../types/message";
import "./style.css";

// ** Import Child Components
import { HumanMessage, BotMessage, BotThinking } from "./message";
import { getChatHistory, getAnswerAgain } from "../../store/messages/actions";
import { Box, IconButton } from "@mui/material";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

interface ContentContainerProps {
  showSuggestions: boolean;
}

const ContentContainer: FC<ContentContainerProps> = ({
  showSuggestions,
}) => {
  const dispatch = useDispatch();
  const { model, chatSearchFilters } = useSelector(
    (state: any) => state.dataFlow
  );
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const { loading, end, messages, answerError } = useSelector(
    (state: any) => state.msg
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const Messages = useMemo(
    () =>
      messages.map((_item: Message, index: number) => {
        if (_item.sender === "human") {
          return <HumanMessage key={index} message={_item.message} />;
        } else {
          return (
            <>
              <BotMessage
                showSuggestions={showSuggestions}
                questionId={_item?.id && _item?.id}
                key={index}
                model={_item?.model ? _item?.model : null}
                tokens={_item?.tokens ? _item?.tokens : null}
                question={_item?.question ? _item?.question : ""}
                trigger={_item?.intent ? _item?.intent : ""}
                messageIndex={index}
                message={_item.message}
                feedback={_item.feedback}
                refer={_item.refer}
              />
            </>
          );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages]
  );

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    setIsMounted(true);
    !messages.length && dispatch(getChatHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [isMounted, loading]);

  const answerAgainHandler = () => {
    dispatch(
      getAnswerAgain({
        finalChat: messages[0].message,
        llm: model?.name ? model?.name?.toLocaleLowerCase() : "gpt-4o",
        type: model?.type ? model?.type : "gpt",
        science: chatSearchFilters?.science,
        enthe_wisdom: chatSearchFilters?.enthe_wisdom,
        legal: chatSearchFilters?.legal,
        ref: chatSearchFilters?.numOfRefs ? chatSearchFilters?.numOfRefs : "5",
        filters: {
          jurisdiction: {
            federal: chatSearchFilters?.jurisdiction?.federal
              ? chatSearchFilters?.jurisdiction?.federal
              : "",
            state: chatSearchFilters?.state ? chatSearchFilters?.state : "",
            courtLevel: chatSearchFilters?.courtLevel
              ? chatSearchFilters?.courtLevel
              : "",
          },
          document_type: chatSearchFilters?.documentType
            ? chatSearchFilters?.documentType
            : "",
          decision_date: {
            from: chatSearchFilters?.decision_date?.from
              ? chatSearchFilters?.decision_date?.from
              : "",
            to: chatSearchFilters?.decision_date?.to
              ? chatSearchFilters?.decision_date?.to
              : "",
          },
          filing_date: {
            from: chatSearchFilters?.filing_date?.from
              ? chatSearchFilters?.filing_date?.from
              : "",
            to: chatSearchFilters?.filing_date?.to
              ? chatSearchFilters?.filing_date?.to
              : "",
          },
        },
      })
    );
  };

  return (
    <InfiniteScroll
      dataLength={messages.length}
      next={() => {
        !end && dispatch(getChatHistory(messages[messages.length - 1].id));
      }}
      style={{ display: "flex", flexDirection: "column-reverse" }}
      inverse={true}
      hasMore={true}
      loader={!end && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
      scrollableTarget="scrollableDiv"
    >
      <div ref={messagesEndRef}></div>
      {answerError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="Refresh"
            sx={{ border: "2px solid #A2A5C6", mr: "20px" }}
            onClick={answerAgainHandler}
          >
            <RefreshRoundedIcon />
          </IconButton>
          Please request again.
        </Box>
      )}
      {loading && <BotThinking />}
      {Messages}
    </InfiniteScroll>
  );
};

export default ContentContainer;
