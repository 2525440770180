import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import { httpMushPro } from "../../../services/http-common";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import { IoChevronBack } from "react-icons/io5";
import { sendReportGPT } from "../../../store/bot/actions";

const ReportGpt = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReportSubmit, setIsReportSubmit] = useState(false);
  const [content, setContent] = useState("");
  const { botDetails, isReportingGPT } = useSelector((state) => state.bot);
  const [dispatchHttp] = useMainController();
  const dispatch = useDispatch();

  const toogleOpen = () => {
    isReportingGPT
      ? dispatch(sendReportGPT(false))
      : dispatch(sendReportGPT(true));
  };

  const sendReport = useMutation(
    () => {
      return httpMushPro.post(
        `user/report-bot`,
        {
          reason: content || "",
          botId: botDetails?.id,
        },
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        dispatch(sendReportGPT(false));
        setIsReportSubmit(true);
        toogleOpen();
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setIsReportSubmit(false);
        setLoading(false);
      },
    }
  );

  const submitReport = () => {
    setLoading(true);
    sendReport.mutate(content);
  };
  const handleSelectOption = (text) => {
    setSelectedOption(text);
  };

  return (
    <>
      <Dialog size="sm" open={isReportingGPT} handler={toogleOpen}>
        <DialogHeader className="flex justify-between items-center">
          <span className="text-base text-[#000000]">
            Report {botDetails.botName}
          </span>
          <MdOutlineClose className="cursor-pointer" onClick={toogleOpen} />
        </DialogHeader>
        <DialogBody divider className="grid  ">
          <div className="mb-4">
            <div className="text-sm font-semibold text-[#000000]">
              {isReportSubmit
                ? "Report submitted "
                : "Please tell us why you are reporting"}
            </div>
            <div className="text-sm  text-[#7d7d7d]">
              {isReportSubmit
                ? "You report has been submitted. Thank you for letting us know about this issue. "
                : "Your help allows us to take the correct action on the reported GPT"}
            </div>
          </div>
          {!isReportSubmit && (
            <>
              {selectedOption ? (
                <>
                  <div className="divide-y overflow-hidden mb-2 ">
                    <span
                      className="w-full  p-2 text-[#000000] text-left text-sm flex justify-start gap-2 cursor-pointer"
                      onClick={() => handleSelectOption(null)}
                    >
                      <IoChevronBack />
                      {selectedOption}
                    </span>
                  </div>
                  <textarea
                    className=" w-full rounded-md p-2  border-2  border-[#0d0d0d] text-sm text-[#0d0d0d] focus:border-[#0d0d0d] focus:shadow-none focus:outline-none focus:ring-[#0d0d0d] !bg-[#fff]"
                    placeholder="Please provide more details"
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                  ></textarea>
                  <div className="flex justify-end mt-3">
                    <Button
                      disabled={loading}
                      size="sm"
                      className="rounded-md"
                      onClick={submitReport}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              ) : (
                <div className="divide-y overflow-hidden rounded-md border border-token-border-medium">
                  {[
                    "This GPT doesn't work as it's described",
                    "This GPT is just trying to get me to pay for something",
                    "This GPT is an exact copy of another GPT",
                    "This GPT is unsafe or illegal",
                  ].map((itm) => (
                    <>
                      <button
                        className="w-full border-token-border-medium p-2 text-[#000000] text-left text-sm hover:bg-[#ececec]"
                        onClick={() => handleSelectOption(itm)}
                      >
                        {itm}
                      </button>
                    </>
                  ))}
                </div>
              )}
            </>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ReportGpt;
