import { Button, Dialog, DialogBody, Spinner } from "@material-tailwind/react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import { httpMushPro } from "../../services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";
import { showGPTDetails } from "../../store/bot/actions";

const ShowGptDetails = (props) => {
  const { uniqueName } = useParams();
  const dispatch = useDispatch();
  const [dispatchHttp] = useMainController();
  const { isShowingGPT } = useSelector((state) => state.bot);
  const { toogleOpen, gptUniqueName } = props;
  const [botData, setBotData] = useState(null);

  const getBotData = useMutation(
    (uniqueName) => {
      return httpMushPro.post(
        `user/getBotData/${uniqueName}`,
        {},
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        setBotData(data.bot);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const handleClose = () => {
    setBotData(null);
    toogleOpen();
  };

  // const handleConservation = (botId, text) => {
  //   setSearchParams({ q: text });
  //   navigate(`/g/${botId}/?q=${encodeURIComponent(text)}`);
  //   handleHideModal();
  // };

  const handleHideModal = () => {
    dispatch(showGPTDetails(false));
  };

  useEffect(() => {
    getBotData.mutate(gptUniqueName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog size="md" open={isShowingGPT} handler={handleClose}>
        <DialogBody
          divider
          className={`grid place-items-center gap-4 ${
            !botData && "min-h-[500px]"
          }`}
        >
          {botData ? (
            <>
              <div className="w-full flex justify-end items-center">
                <MdOutlineClose
                  className="cursor-pointer"
                  onClick={handleClose}
                />
              </div>
              <div className="relative flex flex-grow flex-col gap-4 overflow-y-auto px-6 pb-2 pt-2">
                <div className="flex  flex-col items-center justify-center text-token-text-primary !h-fit">
                  <div className="relative">
                    <div className="mb-3 !h-20 !w-20">
                      <div className=" overflow-hidden rounded-full">
                        <img
                          src={botData?.iconUrl}
                          className="h-full w-full  flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
                          alt={botData?.botName}
                          width="80"
                          height="80"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <div className="text-center  ">
                      <span className="text-2xl font-semibold text-[#0d0d0d]">
                        {botData?.botName}
                      </span>
                      <div className="text-sm my-1 text-[#b7b7b7]">
                        By {botData?.coach?.firstName}
                      </div>
                    </div>

                    <div className="max-w-md text-center text-sm font-normal text-token-text-primary">
                      {botData?.primaryDomain}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                {[
                  {
                    count: botData?.reviews?.averageRating,
                    title: "Review",
                  },
                  {
                    count: botData?.tone,
                    title: "Tone",
                  },
                ].map((props, idx) => (
                    <div key={idx} className="flex flex-col justify-center items-center gap-2 border-l border-token-border-heavy first:border-0 w-48 mt-4 px-2">
                      <div className="flex flex-row items-center gap-1.5 pt-1 text-xl font-semibold text-center leading-none text-black">
                        {props.title === "Review" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            className="icon-sm w-5 h-5"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              fillRule="evenodd"
                              d="M9.822 2.077c.956-1.696 3.4-1.696 4.356 0l2.085 3.7a.5.5 0 0 0 .337.245l4.164.84c1.91.386 2.664 2.71 1.346 4.143l-2.875 3.127a.5.5 0 0 0-.128.396l.487 4.22c.224 1.934-1.753 3.37-3.524 2.56l-3.862-1.768a.5.5 0 0 0-.416 0L7.93 21.308c-1.771.81-3.748-.626-3.524-2.56l.487-4.22a.5.5 0 0 0-.128-.396L1.89 11.005C.572 9.571 1.327 7.248 3.236 6.862l4.164-.84a.5.5 0 0 0 .336-.244z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        )}
                        {props.count ? props.count : "N/A"}
                      </div>
                      <div className="text-xs text-token-text-tertiary text-[#b7b7b7]">
                        {props.title}
                      </div>
                    </div>
                ))}
              </div>

              {botData?.additionalCertification && (
                <div className="flex flex-col w-full">
                  <div className="font-bold mt-6 mb-2">Certificates</div>
                  {botData?.additionalCertification
                    ?.split(", ")
                    ?.map((certificate) => (
                      <div className="flex flex-row items-start gap-2 py-1 text-sm">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-sm mt-0.5 text-green-600"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <div>{certificate}</div>
                      </div>
                    ))}
                </div>
              )}
              <div className=" mt-2">
                <div className="flex flex-grow flex-col items-center">
                  {!uniqueName && (
                    <Link
                      to={`/g/${botData?.uniqueName}`}
                      onClick={handleHideModal}
                      className="btn relative btn-primary h-12 w-full"
                    >
                      <Button
                        size="sm"
                        className="flex w-auto items-center justify-center gap-1.5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                          className="icon-sm"
                        >
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M12 4a8 8 0 0 0-5.687 13.627 1 1 0 0 1 .147 1.217L5.766 20H12a8 8 0 1 0 0-16M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10H4a1 1 0 0 1-.857-1.515l1.218-2.03A9.96 9.96 0 0 1 2 12"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Start Chat
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ShowGptDetails;
