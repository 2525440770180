import {
  Card,
  CardBody,
  Typography,
  Spinner,
  Input,
  Button,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Chip, useTheme } from "@mui/material";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { setSessionExpired, useMainController } from "../../context";
import { timeAgo } from "../../utils/dateFormat";
import { useDispatch } from "react-redux";
import { saveTokenSummary } from "../../store/dataFlow/actions";

const TABLE_HEAD = [
  {
    head: "Detail",
    customeStyle: "!text-left",
  },
  {
    head: "No. of Token",
    customeStyle: "text-center",
  },
  {
    head: "Date",
    customeStyle: "text-right",
  },
];
export function Tokenlogs() {
  const theme = useTheme();
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const dispatchHttp = useMainController();
  const [couponCode, setCouponCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponMessage, setCouponMessage] = useState({
    error: null,
    success: null,
  });

  const checkCoupon = useMutation(
    () => {
      return http.post("coupon/redeem", {
        code: couponCode,
      });
    },
    {
      onSuccess: ({ data }) => {
        setLoading(false);
        setCouponMessage({
          error: null,
          success: data.message,
        });
        getTokenSummary.mutate();
        getTokenDetails.mutate();
      },
      onError: (error) => {
        setCouponMessage({
          error: error.response?.data?.message,
          success: null,
        });
        setLoading(false);
      },
    }
  );

  const handleSubmit = () => {
    setLoading(true);
    checkCoupon.mutate();
  };

  const getTokenSummary = useMutation(
    () => {
      return http.get(
        "tokens/tokenSummary",
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        dispatch(saveTokenSummary(data));
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const getTokenDetails = useMutation(
    () => {
      return http.get(
        `tokens/findAll?ps=10&cp=1`,
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        setDetails(data.records);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    getTokenDetails.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="flex flex-col justify-end  my-2 ">
        <div className="relative ">
          <Input
            type="email"
            placeholder="Enter Coupon"
            // value={"email"}
            onChange={(e) => setCouponCode(e.target.value)}
            className="pr-20 border-2 focus:!border-t-2  focus:!border-t-black "
            containerProps={{
              className: "min-w-0",
            }}
            labelProps={{
              className: "hidden",
            }}
          />
          <Button
            size="sm"
            color={!loading ? "gray" : "blue-gray"}
            disabled={!loading && couponCode === ""}
            onClick={handleSubmit}
            className="!absolute right-1 top-1 rounded"
          >
            REDEEM
          </Button>
        </div>
        {couponMessage.error && (
          <span className="!text-[#d35858] m-2">{couponMessage.error}</span>
        )}
        {couponMessage.success && (
          <span className="!text-[#3cd19a] m-2">{couponMessage.success}</span>
        )}
      </div>
      <Card
        className={` ${theme.palette.mode === "dark" && "!bg-[#202940]"} mt-4`}
      >
        <CardBody
          className={`overflow-x-auto px-2 pt-0 pb-2  ${
            theme.palette.mode === "dark" && "!bg-[#202940]"
          } `}
        >
          {details ? (
            <table className="w-full min-w-max table-auto">
              <thead>
                <tr>
                  {TABLE_HEAD.map(({ head, customeStyle }) => (
                    <th
                      key={head}
                      className={`border-b border-gray-300 !p-4 pb-8 ${customeStyle}`}
                    >
                      <Typography
                        color="blue-gray"
                        variant="small"
                        className="!font-bold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {details?.map(
                  ({ reason, tokens, transactionType, updated_at }, index) => {
                    const isLast = index === details.length - 1;
                    const classes = isLast
                      ? "!p-4 "
                      : "!p-4 border-b border-gray-300";
                    return (
                      <tr key={index}>
                        <td className={classes}>
                          <div className="flex items-center gap-4 text-left">
                            <div>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="!font-semibold"
                              >
                                {transactionType.replace(/^\w/, (c) =>
                                  c.toUpperCase()
                                )}
                              </Typography>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {reason}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={`${classes} text-center`}>
                          <Chip
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={tokens}
                          />
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="!font-normal text-gray-600 text-right"
                          >
                            {timeAgo(updated_at)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <div className="w-full flex justify-center items-center py-2">
              <Spinner />
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default Tokenlogs;
