import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { httpMushPro } from "../../../services/http-common";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const SuccessSubscription = () => {
  const [searchParams] = useSearchParams();
  const [user_Id] = useState(searchParams.get("userId") || "");
  const [session_id] = useState(searchParams.get("session_id") || "");

  const onSubmit = () => {
    saveContent.mutate();
  };

  const saveContent = useMutation(
    () => {
      return httpMushPro.post(
        `stripe/rewardUserTokens`,
        {
          sessionId: session_id,
          userId: user_Id,
        },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (error) => {
        console.log(error);
      },
      onMutate: () => {
        // setLoading(true);
      },
      onSettled: () => {
        // setLoading(false);
      },
    }
  );

  useEffect(() => {
    user_Id && session_id && onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          my: "auto",
          mx: "auto",
          pt: { xs: 2, md: 4 },
          pb: { xs: 2, md: 4 },
          textAlign: "center",
        }}
      >
        <CheckCircleIcon className="text-[#34ba87] w-20" />
        <Typography variant="h5" className="my-2">
          PAYMENT SUCCESSFUL!
        </Typography>

        <Typography variant="paragraph" color="blue-gray">
          Thanks for subscribing
        </Typography>

        <Container
          sx={{
            textAlign: "center",
            pt: { xs: 1, md: 2 },
          }}
        >
          <Button
            variant="outlined"
            className="border-[#000080] !normal-case  "
          >
            Back to Chat
          </Button>
          <Button
            variant="contained"
            className="border-[#000080] !normal-case !ms-2"
          >
            Check Subscriptions
          </Button>
        </Container>
      </Container>
    </>
  );
};

export default SuccessSubscription;
