import {
  Grid,
  Box,
  Button,
  Typography,
  // Link
} from "@mui/material";
// import { useNavigate } from 'react-router-dom';

import useStyles from "../styles/styles";

import Shape from "../components/Shape";
import { useLocation } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Switch } from "@material-tailwind/react";
import { useState } from "react";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import UserService from "../../../KeyCloackServices/UserService";
// const navigate = useNavigate();

export default function PricingTable({ plans }: any) {
  const [isAnnualBilling, setIsAnnualBilling] = useState("no");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  const clases = {
    header3: {
      fontSize: { xs: "7.5vw", md: "2.6vw !important" },
      fontWeight: "600 !important",
      color: "#000080 !important",
    },
    header1: {
      fontSize: {
        xs: "8.9vw !important",
        md: "3.38vw !important",
      },
      fontWeight: "600 !important",
      // letterSpacing: "-1.33px !important",
    },
    contentText: {
      fontSize: { xs: "4.07vw !important", md: "1.15vw !important" },
      fontWeight: "500 !important",
      color: "#757B8A !important",
      textAlign: { xs: "center", md: "left" },
    },
    buttonSx: {
      width: { md: "22.48vw", xs: "39.95vw" },
      fontSize: { xs: "3.56vw", md: "1.11vw" },
      paddingY: { xs: "5vw", md: "1.5vw" },
    },
  };

  const filteredProducts = plans.filter((product: any) => {
    return (
      product.metadata.year === isAnnualBilling ||
      product.metadata.price === "Free"
    );
  });

  const handleBuy = (id: any, isRecurring: any) => {
    setLoading(true);
    buyProduct.mutate({ id, isRecurring });
  };

  const navigate = (url: any) => (window.location.href = url);

  const buyProduct = useMutation(
    (data: any) => {
      return http.post(
        `stripe/create-checkout-session`,
        {
          priceId: data.id,
          isRecurring: data.isRecurring,
        },
        null
      );
    },
    {
      onSuccess: ({ data }: any) => {
        navigate(data.url);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );
  return (
    <Grid container className={classes.sectionBox}>
      <Shape secNumber="2" />
      <Grid container className={classes.containerGrid}>
        <div
          id="pricing"
          className="w-full flex flex-col justify-center items-center"
        >
          <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8 mb-2 ">
            <Typography sx={clases.header3}>Plans</Typography>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-4 mb-6">
            <div className=" flex justify-center">
              <div className="flex items-center">
                <Switch
                  checked={isAnnualBilling === "yes"}
                  onClick={() =>
                    setIsAnnualBilling(isAnnualBilling === "no" ? "yes" : "no")
                  }
                  id="custom-switch-component"
                  ripple={false}
                  className="h-full w-full checked:bg-[#000080]"
                  containerProps={{
                    className: "w-11 h-6",
                  }}
                  circleProps={{
                    className: "before:hidden left-0.5 border-none",
                  }}
                  crossOrigin={undefined}
                />
                <span className="ml-3 text-sm" id="headlessui-label-:R58bm:">
                  <span className="font-medium text-gray-900 dark:text-gray-200">
                    {isAnnualBilling === "yes"
                      ? "Discounted Annual Billing"
                      : "Monthly Billing"}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="relative z-10 mx-auto max-w-7xl px-3 lg:px-4">
              <div className="mx-auto grid max-w-md grid-cols-1 gap-2 lg:max-w-7xl lg:grid-cols-4 lg:gap-2">
                {filteredProducts &&
                  filteredProducts.map((plan: any) => (
                    <div className="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
                      <div className="p-8 sm:p-7">
                        <div className="flex">
                          <h3
                            className="text-lg font-semibold leading-8 tracking-tight text-[#000080] "
                            id="tier-basic"
                          >
                            {plan.name}
                          </h3>
                          {plan.metadata?.off &&
                            plan.metadata?.off !== "no" &&
                            isAnnualBilling && (
                              <p className=" ml-2 rounded-full flex justify-center items-center text-white bg-[#000080] dark:bg-teal-400/10 px-2 py-1 text-xs font-semibold leading-1 ">
                                {plan.metadata?.off} OFF
                              </p>
                            )}
                        </div>
                        <div className="mt-4 flex items-baseline text-xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
                          {plan.metadata?.price}
                          <span className="text-lg font-semibold leading-8 tracking-normal text-gray-500 dark:text-gray-400"></span>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col p-2">
                        <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 dark:bg-gray-700 p-3 sm:p-4">
                          <ul className="space-y-6">
                            <li className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon className="w-6 text-[#000080]" />
                              </div>
                              <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                                Tokens:{" "}
                                {Number(
                                  plan.metadata?.tokens
                                )?.toLocaleString()}
                              </p>
                            </li>
                            <li className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon className="w-6 text-[#000080]" />
                              </div>
                              <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                                Average Questions:{" "}
                                {plan.metadata?.average_questions}
                              </p>
                            </li>
                            <li className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon className="w-6 text-[#000080]" />
                              </div>
                              <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                                Max References: {plan.metadata?.max_references}
                              </p>
                            </li>
                            <li className="flex items-start">
                              <div className="flex-shrink-0">
                                {plan.metadata?.scientific_documents ===
                                "yes" ? (
                                  <CheckIcon className="w-6 text-[#000080]" />
                                ) : (
                                  <XMarkIcon className="w-6 text-[#ff3a3a]" />
                                )}
                              </div>
                              <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                                Scientific Documents
                              </p>
                            </li>
                            <li className="flex items-start">
                              <div className="flex-shrink-0">
                                {plan.metadata?.legal_documents === "yes" ? (
                                  <CheckIcon className="w-6 text-[#000080]" />
                                ) : (
                                  <XMarkIcon className="w-6 text-[#ff3a3a]" />
                                )}
                              </div>
                              <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                                Legal References
                              </p>
                            </li>
                            <li className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon className="w-6 text-[#000080]" />
                              </div>
                              <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                                {plan.metadata?.support} Support
                              </p>
                            </li>
                          </ul>
                          {location.pathname !== "/" && (
                            <div className="flex">
                              <Button
                                disabled={loading}
                                size="small"
                                type="button"
                                onClick={() =>
                                  handleBuy(
                                    plan.default_price.id,
                                    !!plan.default_price.recurring
                                  )
                                }
                                variant="contained"
                                className="!w-full"
                                sx={{
                                  mt: 2,
                                  borderRadius: "10px",
                                  height: "50.65px",
                                  mb: "20px",
                                }}
                              >
                                Buy Now
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {location.pathname === "/" && (
          <>
            <Box
              className={classes.flexCenter}
              sx={{
                gap: "2vw",
                p: { md: "1vw", xs: "5vw" },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={clases.buttonSx}
                onClick={() =>
                  UserService.doLogin({
                    redirectUri: `${window.location.href + "pay"}`,
                  })
                }
              >
                Upgrade Now!
              </Button>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
}
