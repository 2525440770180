import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Textarea,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import * as messages from "./../../services/messages";
import { useMutation } from "react-query";
import http from "./../../services/http-common";
import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { useTheme } from "@mui/material";

function EditFaqs({ id, getFaqs }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [faqContent, setFaqContent] = useState({
    question: "",
    answer: "",
  });
  const [loading, setLoading] = useState(false);

  const getFaqById = useMutation(
    (faqId) => {
      return http.post("user/quick-replies/findById", {
        id: faqId,
      });
    },
    {
      onSuccess: ({ data }) => {
        setFaqContent(data?.quickReplies);
        setFaqContent({
          ...faqContent,
          question: data?.quickReplies?.question,
          answer: data?.quickReplies?.answer,
        });
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  useEffect(() => {
    getFaqById.mutate(id);
    setFaqContent({ ...faqContent, id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const updateContent = useMutation(
    () => {
      return http.put(
        `user/quick-replies/update`,
        {
          id: id,
          question: faqContent.question,
          answer: faqContent.answer,
        },
        null
      );
    },
    {
      onSuccess: () => {
        toast.success("Successfully Updated!");
        getFaqs();
        setFaqContent({
          question: "",
          answer: "",
        });
        handleOpen();
      },
      onError: (error) => {
        errorFunc(error);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleOpen = () => {
    setOpen(!open);
  };

  const onChangeContent = (e) => {
    if (loading) return false;
    setFaqContent({ ...faqContent, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    updateContent.mutate();
  };

  return (
    <>
      <Tooltip content="Edit">
        <IconButton variant="text" onClick={handleOpen}>
          <FaRegEdit
            color={theme.palette.mode === "dark" && "white"}
            className="h-5 w-5"
          />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        handler={handleOpen}
        className={`h-140 ${theme.palette.mode === "dark" && "!bg-[#282d3d]"}`}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody
          divider
          className={`flex-col max-h-full  ${
            theme.palette.mode === "dark" && "!bg-[#282d3d] border-y-[#363b4e] "
          }`}
        >
          <div
            className={`w-full flex justify-center gap-2 items-center ${
              theme.palette.mode === "dark" && "text-[#e3e3e3]"
            } `}
          >
            Update Suggestion
          </div>
          <form className="mt-8 mb-2  w-auto  max-w-screen-md sm:w-full ">
            <div className="mb-1 flex flex-col gap-6 w-auto ">
              <div className="sm:w-auto md:w-full">
                <Textarea
                  name="question"
                  type="text"
                  label="Question"
                  value={faqContent?.question}
                  onChange={onChangeContent}
                  className={`w-full !border !border-gray-300 ${
                    theme.palette.mode === "dark"
                      ? "text-white"
                      : " focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                  } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
                  labelProps={{
                    className: theme.palette.mode === "dark" && "!text-white ",
                  }}
                />
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
            disabled={loading}
          >
            <span>Close</span>
          </Button>
          <Button
            variant="gradient"
            onClick={onSubmit}
            disabled={loading}
            color={theme.palette.mode === "dark" ? "white" : "green"}
          >
            <span>Update</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default EditFaqs;
