import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Header from "./home/Header";
import Footer from "./home/Footer";
import HomeHero from "./home/HomeHero";
import Introduction from "./home/Introduction";
import AIIntro from "./home/AIIntro";
import RagIntro from "./home/RagIntro";
import ExploreGpts from "./home/ExploreGpts";
import JoinWaitlist from "./home/JoinWaitlist";
import MissionVision from "./home/MissionVision";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../KeyCloackServices/UserService";
import PricePlans from "./home/Pricing/PricePlans";
import PricingTable from "./home/Pricing/PricingTable";
import Tokens from "./home/Pricing/Tokens";
import EntheoGPTPlan from "./home/Pricing/EntheoGPTPlan";
import EntheoGPTPlusPlan from "./home/Pricing/EntheoGPTPlusPlan";
import IntroSection from "./home/Pricing/IntroSection";
import { plansDetails } from "../utils/PlanDetails";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Inter", "sans-serif"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".7vw",
          width: "10vw",
          fontSize: "1vw",
          fontWeight: "700",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "50vw",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "50vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        paragraph: true,
        mb: "1.5vw",
      },
    },
  },
});

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      navigate("/chat");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <>
          <HomeHero />
          <Introduction />
          <AIIntro />
          <RagIntro />
          <ExploreGpts />
          {/* <SaifArchitecture /> */}
          <PricePlans />
          <PricingTable plans={plansDetails} />
          <Tokens />
          <EntheoGPTPlan />
          <EntheoGPTPlusPlan />
          <IntroSection heading="Start Your Journey with EntheoGPT" />
          <MissionVision />
          <JoinWaitlist
            heading="Try Now!"
            text={
              <>
                Try our other apps like{" "}
                <a
                  href="https://psiloscreen.me/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-[#000080] font-bold"
                >
                  PsiloScreen
                </a>{" "}
                Psilocybin Pre-screening and{" "}
                <a
                  href="https://entheo.info/product/entheodna-home-kit/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-[#000080] font-bold"
                >
                  EntheoDNA
                </a>{" "}
                Entheogenic Home Senstivity Test Kit
              </>
            }
          />
        </>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Home;
