import {Card, CardBody, CardHeader, Input, Typography,} from "@material-tailwind/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useMutation} from "react-query";
import {toast} from "react-toastify";
import {httpMushPro} from "../../services/http-common";
import {formatDate} from "../../lib/util";
import EditUserDetails from "./users/EditUserDetails";
import DeleteUser from "./users/DeleteUser";
import RequestsForUpgrade from "./RequestsForUpgrade";
import {useForm} from "react-hook-form";
import {debounce} from "lodash";
import {useTheme} from "@mui/material";

export function Users() {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [filterBy] = useState("all");
  const [filterValue, setFilterValue] = useState("");

  const { register } = useForm({
    mode: "onSubmit",
  });
  const getUsers = useMutation(
    () => {
      return httpMushPro.post(`user/get-users`, { query: "" });
    },
    {
      onSuccess: ({ data }) => {
        setUsers([...data.userInfo]);
      },
      onError: () => {
        toast.error("Fetch data failed");
      },
    }
  );

  const filteredUsers = useMemo(() => {
    return users.filter((user) =>
        user?.firstName?.toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [users, filterValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deb = useCallback(
    debounce((text) => setFilterValue(text), 1000),
    []
  );
  const handleFilterChange = (e) => {
    deb(e.target.value);
  };

  useEffect(() => {
    getUsers.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy]);

  window.document.title = "EntheoGPT | Admin | Users";
  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card className={` ${theme.palette.mode === "dark" && "!bg-[#202940]"}`}>
        <CardHeader
          variant="gradient"
          className={`mb-8 p-6 flex sm:flex-row flex-column justify-between items-center ${
            theme.palette.mode === "dark" ? "!bg-[#2b344a]" : "!bg-[#2b2b2b]"
          }`}
        >
          <Typography variant="h6" color="white">
            {filterBy === "all" ? "Users" : "All Requests for Upgrade"}
          </Typography>
        </CardHeader>

        {filterBy === "all" ? (
          <>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
              <div className="flex w-full justify-end py-1">
                <div className="mx-2 w-72">
                  <Input
                    placeholder="Search"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    className={`!border !border-gray-300 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 ${
                      theme.palette.mode === "dark" && "text-white"
                    }`}
                    containerProps={{ className: "min-w-[100px]" }}
                    {...register("name")}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <table className="w-full min-w-[640px] table-auto">
                <thead>
                  <tr>
                    {[
                      "fullname",
                      "email",
                      "Username",
                      "create at",
                      "Profile",
                      "Actions",
                    ].map((el, index) => (
                      <th
                        key={index}
                        className="border-b border-blue-gray-50 py-3 px-5 text-left"
                      >
                        <Typography
                          variant="small"
                          className="text-[11px] font-bold uppercase text-blue-gray-400"
                        >
                          {el}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(filteredUsers || users).map((val, key) => {
                    const className = `py-3 px-5 ${
                      key === 0 ? "" : "border-b border-blue-gray-50"
                    }`;

                    return (
                      <tr key={key}>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <div>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className={`font-semibold ${
                                  theme.palette.mode === "dark" &&
                                  "text-[#cdcdcd]"
                                }`}
                              >
                                {val.firstName}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <div>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className={`font-semibold ${
                                  theme.palette.mode === "dark" &&
                                  "text-[#cdcdcd]"
                                }`}
                              >
                                {val.email}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <div>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className={`font-semibold ${
                                  theme.palette.mode === "dark" &&
                                  "text-[#cdcdcd]"
                                }`}
                              >
                                {val.username}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            <div>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className={`font-semibold ${
                                  theme.palette.mode === "dark" &&
                                  "text-[#cdcdcd]"
                                }`}
                              >
                                {formatDate(val.createdTimestamp)}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={className}>
                          <EditUserDetails
                            title={"Edit"}
                            user={val && val}
                            getUsers={getUsers}
                          />
                        </td>
                        <td className={className}>
                          <DeleteUser user={val && val} getUsers={getUsers} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
          </>
        ) : (
          <RequestsForUpgrade />
        )}
      </Card>
    </div>
  );
}

