import React, { FC } from 'react';
import { Grid, Typography, Box } from '@mui/material';

interface Item {
	icon: string;
	name: string;
	contact: string;
}

const FooterContact: FC<Item> = ({ icon, name, contact }) => {
	const clases = {
		footerContactinfo: {
			fontSize: { xs: '4.07vw', md: '1.04vw' },
			fontWeight: '400 !important',
			color: '#FFFFFF !important',
			margin: '0 !important',
			paragraph: 'false',
		},

		footerContactName: {
			fontSize: { xs: '4.58vw', md: '1.04vw' },
			fontWeight: '700 !important',
			color: '#FFFFFF !important',
			margin: '0 !important',
			paragraph: 'false',
		},
	};
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1vw' }}>
			<Grid item xs={2} md={2}>
				<Box
					component="img"
					src={'/images/home/' + icon + '.png'}
					alt="logo"
					sx={{ width: '100%' }}
				/>
			</Grid>
			<Grid item xs={0.5} md={0.5}></Grid>
			<Grid item xs={6} md={6}>
				<Typography sx={clases.footerContactName}>{name}</Typography>
				<Typography sx={clases.footerContactinfo}>{contact}</Typography>
			</Grid>
		</Box>
	);
};

export default FooterContact;
