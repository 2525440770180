import { useRef, useEffect, useState, useCallback } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";

// ** Import MUI
import { IconButton, Box, useTheme, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

// ** Import Type
import { FC } from "react";

// ** Import action
import { getAnswer, updateChatStreaming } from "../../store/messages/actions";
import FilterSearch from "./FilterSearch";
interface ContentEditProps {
  setShowSuggestions: (showSuggestions: boolean) => void;
  handleStopStreaming: () => void;
  isMaximizedChat: boolean;
  setIsMaximizedChat: (isMaximizedChat: boolean) => void;
}

const ContentEdit: FC<ContentEditProps> = ({
  setShowSuggestions,
  handleStopStreaming,
  isMaximizedChat,
  setIsMaximizedChat,
}) => {
  const { isStreaming } = useSelector((state: any) => state.msg);
  const dispatch = useDispatch();
  const { model, chatSearchFilters } = useSelector(
    (state: any) => state.dataFlow
  );
  const theme = useTheme();
  const [text, setText] = useState("");
  const inputBoxRef = useRef(null);

  const handleChange = (evt: any) => {
    // const value = evt.target.value.replace(/<[^>]+>/g, "");
    const value = evt.target.value.replace(/<\/?[^>]+(>|$)/g, "");
    setText(value);
  };

  const onSubmit = useCallback(() => {
    if (text !== "") {
      dispatch(
        getAnswer({
          finalChat: text,
          llm: model?.name ? model?.name?.toLocaleLowerCase() : "gpt-4o",
          type: model?.type ? model?.type : "gpt",
          science: chatSearchFilters?.science,
          enthe_wisdom: chatSearchFilters?.enthe_wisdom,
          legal: chatSearchFilters?.legal,
          ref: chatSearchFilters?.numOfRefs ? chatSearchFilters?.numOfRefs : 5,
          filters: {
            jurisdiction: {
              federal: chatSearchFilters?.jurisdiction?.federal
                ? chatSearchFilters?.jurisdiction?.federal
                : "",
              state: chatSearchFilters?.state ? chatSearchFilters?.state : "",
              courtLevel: chatSearchFilters?.courtLevel
                ? chatSearchFilters?.courtLevel
                : "",
            },
            document_type: chatSearchFilters?.documentType
              ? chatSearchFilters?.documentType
              : "",
            decision_date: {
              from: chatSearchFilters?.decision_date?.from
                ? chatSearchFilters?.decision_date?.from
                : "",
              to: chatSearchFilters?.decision_date?.to
                ? chatSearchFilters?.decision_date?.to
                : "",
            },
            filing_date: {
              from: chatSearchFilters?.filing_date?.from
                ? chatSearchFilters?.filing_date?.from
                : "",
              to: chatSearchFilters?.filing_date?.to
                ? chatSearchFilters?.filing_date?.to
                : "",
            },
          },
        })
      );
      dispatch(updateChatStreaming(true));
      setShowSuggestions(false);
      setText("");
      setIsMaximizedChat(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, dispatch]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          onSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMaximizedChat ? `column` : "row",
          alignItems: "center",
          position: "relative",
        }}
      >
        {!isMaximizedChat && <FilterSearch />}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
          className="w-full ms-2"
        >
          <ContentEditable
            disabled={isStreaming}
            className={`content-editable ${
              isMaximizedChat && "!min-h-[48rem]"
            } !p-[12px_24px] !rounded-md ${isStreaming && "cursor-wait"} ${
              theme?.palette.mode === "dark" && "!bg-[#1a2035]"
            }`}
            data-text="Send a message"
            innerRef={inputBoxRef}
            html={text}
            onChange={handleChange}
          />
          {!isMaximizedChat && (
            <>
              {isStreaming ? (
                <IconButton
                  sx={{ position: "absolute", right: "5px" }}
                  aria-label="Submit"
                  onClick={handleStopStreaming}
                >
                  <StopCircleIcon color="primary" />
                </IconButton>
              ) : (
                <div className="absolute right-1">
                  <FullscreenIcon
                    className="cursor-pointer"
                    color="primary"
                    onClick={() => {
                      setIsMaximizedChat(!isMaximizedChat);
                    }}
                  />
                  <IconButton aria-label="Submit" onClick={onSubmit}>
                    <SendIcon color="primary" />
                  </IconButton>
                </div>
              )}
            </>
          )}
        </Box>
        {isMaximizedChat && (
          <div className="self-end mt-2">
            {isStreaming ? (
              <Button
                variant="outlined"
                onClick={handleStopStreaming}
                startIcon={<StopCircleIcon color="primary" />}
              >
                Stop
              </Button>
            ) : (
              <Button
                size="small"
                aria-label="Submit"
                variant="contained"
                onClick={onSubmit}
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            )}
          </div>
        )}
      </Box>
    </>
  );
};

export default ContentEdit;
