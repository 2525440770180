import { useRef, useEffect, useState, useCallback } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";

// ** Import MUI
import { IconButton, Box, useTheme, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
// ** Import Type
import { FC } from "react";

// ** Import action
import {
  addFavGpts,
  getBotAnswer,
  updateBotChatStreaming,
} from "../../store/bot/actions";
interface ContentEditProps {
  setShowSuggestions: (showSuggestions: boolean) => void;
  handleStopStreaming: () => void;
  isMaximizedChat: boolean;
  setIsMaximizedChat: (isMaximizedChat: boolean) => void;
}

const ContentEdit: FC<ContentEditProps> = ({
  setShowSuggestions,
  handleStopStreaming,
  isMaximizedChat,
  setIsMaximizedChat,
}) => {
  const dispatch = useDispatch();
  const { botDetails, isBotStreaming } = useSelector((state: any) => state.bot);
  const theme = useTheme();
  const [text, setText] = useState("");
  const inputBoxRef = useRef(null);

  const handleChange = (evt: any) => {
    const value = evt.target.value.replace(/<[^>]+>/g, "");
    setText(value);
  };

  const onSubmit = useCallback(() => {
    if (text !== "") {
      dispatch(addFavGpts(botDetails?.id));
      dispatch(
        getBotAnswer({
          botId: botDetails?.id,
          question: text,
          llm: JSON.parse(
            localStorage.getItem("model") || "{}"
          )?.model?.toLocaleLowerCase(),
        })
      );
      dispatch(updateBotChatStreaming(true));
      setShowSuggestions(false);
      setText("");
      setIsMaximizedChat(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, dispatch]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          onSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ContentEditable
          disabled={isBotStreaming}
          className={`content-editable ${
            isMaximizedChat && "!min-h-[48rem]"
          } !p-[12px_24px] !rounded-md ${isBotStreaming && "cursor-wait"} ${
            theme?.palette.mode === "dark" && "!bg-[#1a2035]"
          }`}
          data-text="Send a message"
          innerRef={inputBoxRef}
          html={text}
          onChange={handleChange}
        />
        {!isMaximizedChat && (
          <>
            {isBotStreaming ? (
              <IconButton
                sx={{ position: "absolute", right: "5px" }}
                aria-label="Submit"
                onClick={handleStopStreaming}
              >
                <StopCircleIcon color="primary" />
              </IconButton>
            ) : (
              <div className="absolute right-1">
                <FullscreenIcon
                  className="cursor-pointer"
                  color="primary"
                  onClick={() => {
                    setIsMaximizedChat(!isMaximizedChat);
                  }}
                />
                <IconButton aria-label="Submit" onClick={onSubmit}>
                  <SendIcon color="primary" />
                </IconButton>
              </div>
            )}
          </>
        )}
      </Box>
      {isMaximizedChat && (
        <div className="flex justify-end items-center mt-2">
          {isBotStreaming ? (
            <Button
              variant="outlined"
              onClick={handleStopStreaming}
              startIcon={<StopCircleIcon color="primary" />}
            >
              Stop
            </Button>
          ) : (
            <Button
              size="small"
              aria-label="Submit"
              variant="contained"
              onClick={onSubmit}
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default ContentEdit;
