import React, { useState } from "react";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IoArrowBackOutline } from "react-icons/io5";
import { saveMsgRefs } from "../store/dataFlow/actions";
import ScientificReSearchDetails from "../components/chat/Research Details/ScientificReSearchDetails";
import LegalReSearchDetails from "../components/chat/Research Details/LegalReSearchDetails";
import UpgradePlan from "../components/Upgrade/UpgradePlan";
import PricingSection from "./home/Pricing/PricingSection";
import { plansDetails } from "../utils/PlanDetails";

const entheoProPlan = plansDetails.filter((plan) => plan.name === "EntheoPro");

const AllSources = () => {
  const dispatch = useDispatch();
  const { isSubscriptionAvailable, selectedMessageRefs } = useSelector(
    (state) => state.dataFlow
  );
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [selectedRef, setSelectedRef] = useState({
    title: null,
    refDetails: null,
  });

  const handleUpgrade = (refDetails, title) => {
    if (openUpgrade) {
      setSelectedRef({
        title: null,
        refDetails: null,
      });
    } else {
      setSelectedRef({
        title: title,
        refDetails: refDetails,
      });
    }
    setOpenUpgrade(!openUpgrade);
  };

  const handleCloseRef = () => {
    dispatch(saveMsgRefs(null));
  };

  return (
    <React.Fragment>
      <div
        className="overflow-y-auto max-h-[calc(100vh-230px)] pb-5  md:max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-205px)] xl:max-h-[calc(100vh-205px)]"
        // className="h-auto"
        // className="  flex flex-col-reverse !min-h-[calc(100vh-240px)] md:max-h-[calc(100vh-270px)] lg:max-h-[calc(100vh-270px)] xl:max-h-[calc(100vh-270px)]"
      >
        {openUpgrade && (
          <div className="modal-header flex justify-between items-center px-3 py-4">
            <div className="flex justify-start items-center">
              {openUpgrade && (
                <span onClick={handleUpgrade}>
                  <IoArrowBackOutline className="mr-3 h-5 w-5 cursor-pointer" />
                </span>
              )}
              <span className={`modal-title font-bold `}>
                {openUpgrade ? "Back to References" : " References"}
              </span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-3 h-5 w-5 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                handleCloseRef();
              }}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}

        {openUpgrade ? (
          <>
            {isSubscriptionAvailable ? (
              <>
                {selectedRef?.title === "science" ? (
                  <ScientificReSearchDetails id={selectedRef.refDetails.id} />
                ) : (
                  <LegalReSearchDetails id={selectedRef.refDetails.id} />
                )}
              </>
            ) : (
              <>
                <UpgradePlan open={openUpgrade} handleUpgrade={handleUpgrade} />{" "}
                <PricingSection plans={entheoProPlan} />
              </>
            )}
            {/* {selectedRef?.title === "science" ? (
                <ScientificReSearchDetails id={selectedRef.refDetails.id} />
              ) : (
                <LegalReSearchDetails id={selectedRef.refDetails.id} />
              )} */}
          </>
        ) : (
          <div >
            <div>
              <div className="radio-toolbar">
                {selectedMessageRefs &&
                  selectedMessageRefs?.science.length > 0 && (
                    <>
                      <div className="sticky-header sticky top-0 bg-white rightbar-title px-3 py-4">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCloseRef();
                          }}
                          className="right-bar-toggle float-end"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="absolute top-2 right-2  h-5 w-5 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCloseRef();
                            }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                              clipRule="evenodd"
                            />
                          </svg>{" "}
                        </Link>
                        <h5 className="m-0 font-bold text-base underline">
                          Science
                        </h5>
                        <hr className="mt-1" />
                      </div>
                      <div className="flex flex-col px-0 py-2">
                        {selectedMessageRefs?.science.map((item, index) => {
                          return (
                            <div
                              className="hover:bg-gray-100"
                              style={{ opacity: 1, willChange: "auto" }}
                              onClick={() => {
                                handleUpgrade(item, "science");
                              }}
                            >
                              <div className="flex flex-col gap-0.5 rounded-xl px-3 py-2.5 hover:bg-token-main-surface-secondary">
                                <div className="line-clamp-2 text-sm font-semibold">
                                  {index + 1 + ". "}
                                  {item.title || item.name}
                                  {item.author && " by " + item.author}
                                </div>
                                <div className="line-clamp-2 text-sm font-normal leading-snug text-token-text-secondary">
                                  {(item.page || item.section) && (
                                    <>
                                      {"("}
                                      {typeof item.page === "string" ? (
                                        <>
                                          {item.page
                                            ?.toString()
                                            .split(",")
                                            .map((itm, idx) => (
                                              <React.Fragment key={idx}>
                                                {`${
                                                  itm !== "undefined"
                                                    ? `p. ${itm}`
                                                    : ""
                                                } sec. ${
                                                  item.section
                                                    .toString()
                                                    .split(",")[idx]
                                                } ${
                                                  item.section
                                                    .toString()
                                                    .split(",").length !==
                                                  idx + 1
                                                    ? ","
                                                    : ""
                                                } `}
                                              </React.Fragment>
                                            ))}
                                        </>
                                      ) : (
                                        <>
                                          {(item.page || item.section) && (
                                            <>
                                              {`${
                                                item.page
                                                  ? "p." + item.page + " "
                                                  : ""
                                              }${
                                                item.section &&
                                                "sec." + item.section
                                              }`}
                                            </>
                                          )}
                                        </>
                                      )}
                                      {") "}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
              </div>

              <div className="mt-2 radio-toolbar">
                {selectedMessageRefs &&
                  selectedMessageRefs?.legal.length > 0 && (
                    <>
                      <div className="sticky-header sticky top-0 bg-white rightbar-title px-3 py-4">
                        {selectedMessageRefs &&
                          !selectedMessageRefs?.science.length > 0 && (
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCloseRef();
                              }}
                              className="right-bar-toggle float-end"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="mr-3 h-5 w-5 cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCloseRef();
                                }}
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                  clipRule="evenodd"
                                />
                              </svg>{" "}
                            </Link>
                          )}
                        <h5 className="m-0 font-bold text-base underline">
                          Legal
                        </h5>
                        <hr className="mt-1" />
                      </div>
                      {selectedMessageRefs?.legal.map((item, index) => {
                        return (
                          <div
                            className="hover:bg-gray-100"
                            style={{ opacity: 1, willChange: "auto" }}
                            onClick={() => handleUpgrade(item, "legal")}
                          >
                            <div className="flex flex-col gap-0.5 rounded-xl px-3 py-2.5 hover:bg-token-main-surface-secondary">
                              <div className="line-clamp-2 text-sm font-semibold">
                                {index + 1 + ". "}
                                {item.name || item?.title}
                                {item.author && " by " + item.author}
                              </div>
                              <div className="line-clamp-2 text-sm font-normal leading-snug text-token-text-secondary">
                                {(item.page || item.section) && (
                                  <>
                                    {"("}
                                    {typeof item.page === "string" ? (
                                      <>
                                        {item.page
                                          ?.toString()
                                          .split(",")
                                          .map((itm, idx) => (
                                            <React.Fragment key={idx}>
                                              {`${
                                                itm !== "undefined"
                                                  ? `p. ${itm}`
                                                  : ""
                                              } sec. ${
                                                item.section
                                                  .toString()
                                                  .split(",")[idx]
                                              } ${
                                                item.section
                                                  .toString()
                                                  .split(",").length !==
                                                idx + 1
                                                  ? ","
                                                  : ""
                                              } `}
                                            </React.Fragment>
                                          ))}
                                      </>
                                    ) : (
                                      <>
                                        {(item.page || item.section) && (
                                          <>
                                            {`${
                                              item.page
                                                ? "p." + item.page + " "
                                                : ""
                                            }${
                                              item.section &&
                                              "sec." + item.section
                                            }`}
                                          </>
                                        )}
                                      </>
                                    )}
                                    {") "}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AllSources;
