import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import http from "../../services/http-common";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import { useTheme } from "@mui/material";
import FormattedResponse from "../FormattedResponse";
import UpgradePlan from "../Upgrade/UpgradePlan";
import { IoArrowBackOutline } from "react-icons/io5";
import { plansDetails } from "../../utils/PlanDetails";
import PricingSection from "../../pages/home/Pricing/PricingSection";
import ScientificReSearchDetails from "./Research Details/ScientificReSearchDetails";
import LegalReSearchDetails from "./Research Details/LegalReSearchDetails";
import { saveMsgRefs } from "../../store/dataFlow/actions";

const entheoProPlan = plansDetails.filter((plan) => plan.name === "EntheoPro");
const ShowSources = ({ questionId, question, message, refer }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isSubscriptionAvailable } = useSelector((state) => state.dataFlow);
  const [selectedRef, setSelectedRef] = useState({
    title: null,
    refDetails: null,
  });
  const [open, setOpen] = React.useState(false);
  const [openUpgrade, setOpenUpgrade] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  const [dispatchHttp] = useMainController();

  const handleUpgrade = (refDetails, title) => {
    if (openUpgrade) {
      setSelectedRef({
        title: null,
        refDetails: null,
      });
    } else {
      setSelectedRef({
        title: title,
        refDetails: refDetails,
      });
    }
    setOpenUpgrade(!openUpgrade);
  };

  const handleRefs = (refs) => {
    dispatch(saveMsgRefs(refs));
    // handleOpen();
  };

  const handleOpen = () => setOpen(!open);

  const scrapeWebSiteContent = useMutation(
    (id) => {
      return http.post(
        `chat/email-references`,
        { chatId: id },
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        setOpen(!open);
        toast.success(data.message);
      },
      onError: (err) => {
        errorFunc(err);
      },
    }
  );
  const errorFunc = (error) => {
    let message = error.response.data.message;
    toast.error(message);
  };
  const handleMailSources = (id) => {
    scrapeWebSiteContent.mutate(id);
  };

  return (
    <>
      <div className="flex justify-start  items-center">
        <Typography
          as="a"
          href="#"
          className={`text-sm font-semibold ${
            theme?.palette.mode === "light"
              ? "text-blue-gray-600"
              : "text-white"
          }`}
          onClick={() => {
            handleRefs(refer);
          }}
        >
          Refs: [{refer?.science.length + refer?.legal.length}]
        </Typography>
      </div>
      <Dialog
        open={open}
        size={openUpgrade ? "lg" : ""}
        handler={handleOpen}
        className={theme.palette.mode === "dark" && "!bg-[#282d3d]"}
        animate={{
          mount: { scale: 1, y: -20 },
          unmount: { scale: 0.9, y: 100 },
        }}
      >
        <div className="flex items-center justify-between ">
          <DialogHeader>
            {openUpgrade && (
              <span onClick={handleUpgrade}>
                <IoArrowBackOutline className="mr-3 h-5 w-5 cursor-pointer" />
              </span>
            )}

            <Typography
              variant="h6"
              sx={{ textDecoration: "none", color: "grey" }}
            >
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                }`}
              >
                {openUpgrade ? "Back to References" : " References"}
              </span>
            </Typography>
          </DialogHeader>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5 cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody
          divider
          className={`${
            openUpgrade ? "sm:h-auto max-h-[35rem]" : "max-h-[30rem]"
          } overflow-y-auto  ${
            theme.palette.mode === "dark" && "!bg-[#282d3d] border-y-[#363b4e] "
          }`}
          style={{ scrollbarColor: "#1a2035 #f3f6ff", scrollbarWidth: "thin" }}
        >
          {openUpgrade ? (
            <>
              {isSubscriptionAvailable ? (
                <>
                  {selectedRef?.title === "science" ? (
                    <ScientificReSearchDetails id={selectedRef.refDetails.id} />
                  ) : (
                    <LegalReSearchDetails id={selectedRef.refDetails.id} />
                  )}
                </>
              ) : (
                <>
                  <UpgradePlan
                    open={openUpgrade}
                    handleUpgrade={handleUpgrade}
                  />{" "}
                  <PricingSection plans={entheoProPlan} />
                </>
              )}
            </>
          ) : (
            <>
              <div className="grid gap-1 w-full ">
                <Typography
                  variant="paragraph"
                  className={`no-underline ${
                    theme.palette.mode === "dark"
                      ? "text-[#BDBDBD]"
                      : "text-grey"
                  }`}
                >
                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-[#e3e3e3]"
                    }`}
                  >
                    {user.firstName}:{" "}
                  </span>
                  {parse(question.replace(/\n/g, "<br>"))}
                </Typography>
              </div>
              <br />

              <div className="grid gap-1 w-full ">
                <Typography
                  variant="paragraph"
                  className={`no-underline ${
                    theme.palette.mode === "dark"
                      ? "text-[#BDBDBD]"
                      : "text-grey"
                  }`}
                >
                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-[#e3e3e3]"
                    }`}
                  >
                    EntheoGPT:{" "}
                  </span>
                  <FormattedResponse response={message} />
                  <br />
                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-white"
                    }`}
                  >
                    Generated by{" "}
                    <a
                      href="https://entheogpt.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://entheogpt.com
                    </a>
                  </span>
                  <br />
                  <br />

                  <span
                    className={`font-bold ${
                      theme.palette.mode === "dark" && "text-white"
                    }`}
                  >
                    {" "}
                    References used:{" "}
                  </span>
                  <div>
                    <div className="radio-toolbar">
                      {refer && refer?.science.length > 0 && (
                        <>
                          <div className="sticky-header sticky top-0 bg-white rightbar-title px-3 py-4">
                            <h5 className="m-0 font-bold text-base underline">
                              Science
                            </h5>
                            <hr className="mt-1" />
                          </div>
                          <div className="flex flex-col px-0 py-2">
                            {refer?.science.map((item, index) => {
                              return (
                                <div
                                  className="hover:bg-gray-100"
                                  style={{ opacity: 1, willChange: "auto" }}
                                  onClick={() => {
                                    handleUpgrade(item, "science");
                                  }}
                                >
                                  <div className="flex flex-col gap-0.5 rounded-xl px-3 py-2.5 hover:bg-token-main-surface-secondary">
                                    <div className="line-clamp-2 text-sm font-semibold">
                                      {index + 1 + ". "}
                                      {item.title || item.name}
                                      {item.author && " by " + item.author}
                                    </div>
                                    <div className="line-clamp-2 text-sm font-normal leading-snug text-token-text-secondary">
                                      {(item.page || item.section) && (
                                        <>
                                          {"("}
                                          {typeof item.page === "string" ? (
                                            <>
                                              {item.page
                                                ?.toString()
                                                .split(",")
                                                .map((itm, idx) => (
                                                  <React.Fragment key={idx}>
                                                    {`${
                                                      itm !== "undefined"
                                                        ? `p. ${itm}`
                                                        : ""
                                                    } sec. ${
                                                      item.section
                                                        .toString()
                                                        .split(",")[idx]
                                                    } ${
                                                      item.section
                                                        .toString()
                                                        .split(",").length !==
                                                      idx + 1
                                                        ? ","
                                                        : ""
                                                    } `}
                                                  </React.Fragment>
                                                ))}
                                            </>
                                          ) : (
                                            <>
                                              {(item.page || item.section) && (
                                                <>
                                                  {`${
                                                    item.page
                                                      ? "p." + item.page + " "
                                                      : ""
                                                  }${
                                                    item.section &&
                                                    "sec." + item.section
                                                  }`}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {") "}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="mt-2 radio-toolbar">
                      {refer && refer?.legal.length > 0 && (
                        <>
                          <div className="sticky-header sticky top-0 bg-white rightbar-title px-3 py-4">
                            <h5 className="m-0 font-bold text-base underline">
                              Legal
                            </h5>
                            <hr className="mt-1" />
                          </div>
                          {refer?.legal.map((item, index) => {
                            return (
                              <div
                                className="hover:bg-gray-100"
                                style={{ opacity: 1, willChange: "auto" }}
                                onClick={() => handleUpgrade(item, "legal")}
                              >
                                <div className="flex flex-col gap-0.5 rounded-xl px-3 py-2.5 hover:bg-token-main-surface-secondary">
                                  <div className="line-clamp-2 text-sm font-semibold">
                                    {index + 1 + ". "}
                                    {item.name || item?.title}
                                    {item.author && " by " + item.author}
                                  </div>
                                  <div className="line-clamp-2 text-sm font-normal leading-snug text-token-text-secondary">
                                    {(item.page || item.section) && (
                                      <>
                                        {"("}
                                        {typeof item.page === "string" ? (
                                          <>
                                            {item.page
                                              ?.toString()
                                              .split(",")
                                              .map((itm, idx) => (
                                                <React.Fragment key={idx}>
                                                  {`${
                                                    itm !== "undefined"
                                                      ? `p. ${itm}`
                                                      : ""
                                                  } sec. ${
                                                    item.section
                                                      .toString()
                                                      .split(",")[idx]
                                                  } ${
                                                    item.section
                                                      .toString()
                                                      .split(",").length !==
                                                    idx + 1
                                                      ? ","
                                                      : ""
                                                  } `}
                                                </React.Fragment>
                                              ))}
                                          </>
                                        ) : (
                                          <>
                                            {(item.page || item.section) && (
                                              <>
                                                {`${
                                                  item.page
                                                    ? "p." + item.page + " "
                                                    : ""
                                                }${
                                                  item.section &&
                                                  "sec." + item.section
                                                }`}
                                              </>
                                            )}
                                          </>
                                        )}
                                        {") "}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </Typography>
              </div>
            </>
          )}
        </DialogBody>
        {!openUpgrade && (
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              Close
            </Button>
            <Button
              variant="filled"
              className={`font-bold ${
                theme.palette.mode === "dark" && "!bg-[#f3f6ff] !text-[#1a2035]"
              }`}
              onClick={() => {
                handleMailSources(questionId);
              }}
            >
              Email this result
            </Button>
          </DialogFooter>
        )}
      </Dialog>
    </>
  );
};

export default ShowSources;
