
export const getJwtToken = () => {
  try {
    return localStorage.getItem("botAccessTokenEncoded");
  } catch (error) {
    return null;
  }
};


