import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import http from "../../services/http-common";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../context";
import { useTheme } from "@mui/material";
import FormattedResponse from "../FormattedResponse";

const ShowSources = ({ questionId, question, message, refer }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  const [dispatch] = useMainController();

  const handleOpen = () => setOpen(!open);
  const scrapeWebSiteContent = useMutation(
    (id) => {
      return http.post(
        `chat/email-references`,
        { chatId: id },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setOpen(!open);
        toast.success(data.message);
      },
      onError: (err) => {
        errorFunc(err);
      },
    }
  );
  const errorFunc = (error) => {
    let message = error.response.data.message;
    toast.error(message);
  };
  const handleMailSources = (id) => {
    scrapeWebSiteContent.mutate(id);
  };

  return (
    <>
      <div className="flex justify-start  items-center">
        <Typography
          as="a"
          href="#"
          className={`text-sm font-semibold ${
            theme?.palette.mode === "light"
              ? "text-blue-gray-600"
              : "text-white"
          }`}
          onClick={handleOpen}
        >
          Refs: [{refer.length}]
        </Typography>
      </div>
      <Dialog
        open={open}
        handler={handleOpen}
        className={theme.palette.mode === "dark" && "!bg-[#282d3d]"}
      >
        <div className="flex items-center justify-between ">
          <DialogHeader>
            <Typography
              variant="h6"
              sx={{ textDecoration: "none", color: "grey" }}
            >
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                }`}
              >
                References{" "}
              </span>
            </Typography>
          </DialogHeader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5 cursor-pointer"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody
          divider
          className={`max-h-[30rem] overflow-y-auto  ${
            theme.palette.mode === "dark" && "!bg-[#282d3d] border-y-[#363b4e] "
          }`}
          style={{ scrollbarColor: "#1a2035 #f3f6ff", scrollbarWidth: "thin" }}
        >
          <div className="grid gap-1 w-full ">
            <Typography
              variant="paragraph"
              className={`no-underline ${
                theme.palette.mode === "dark" ? "text-[#BDBDBD]" : "text-grey"
              }`}
            >
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                }`}
              >
                {user.firstName}:{" "}
              </span>
              {parse(question.replace(/\n/g, "<br>"))}
            </Typography>
          </div>
          <br />

          <div className="grid gap-1 w-full ">
            <Typography
              variant="paragraph"
              className={`no-underline ${
                theme.palette.mode === "dark" ? "text-[#BDBDBD]" : "text-grey"
              }`}
            >
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                }`}
              >
                Magic Myc AI:{" "}
              </span>
              <FormattedResponse response={message} />
              {/* {parse(message.replace(/\n/g, "<br>"))} */}
              <br />
              <br />
              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-white"
                }`}
              >
                Generated by:{" "}
              </span>
              <br />
              {"https://EntheoGPT.com Entheo AI chatbot."}
              <br />
              <br />

              <span
                className={`font-bold ${
                  theme.palette.mode === "dark" && "text-white"
                }`}
              >
                {" "}
                References used:{" "}
              </span>
              {refer.map((item, index) => {
                return (
                  <Typography
                    component={`${item.url ? "a" : "p"}`}
                    href={item.url}
                    sx={{
                      textDecoration: "none",
                      color: "grey",
                      wordWrap: "break-word",
                    }}
                    key={index}
                  >
                    <a href={item.url} target="_blank" rel="noreferrer">
                      {item.url?.includes("book") ? (
                        <Typography
                          sx={{ width: "100%", wordWrap: "break-word" }}
                        >
                          {index + 1 + ". Doc:"}
                          {item.title}
                          {/* {item.title.length > 25
                          ? item.title.slice(0, 25)
                          : item.title} */}

                          {item.author && " by " + item.author}

                          {/* {item.author.length > 10
                          ? item.author.slice(0, 10) + "."
                          : item.author} */}
                          {(item.page || item.section) && (
                            <>
                              {". ( "}
                              {typeof item.page === "string" ? (
                                <>
                                  {item.page
                                    ?.toString()
                                    .split(",")
                                    .map((itm, idx) => (
                                      <React.Fragment key={idx}>
                                        {`${
                                          itm !== "undefined" ? `p. ${itm}` : ""
                                        } sec. ${
                                          item.section.toString().split(",")[
                                            idx
                                          ]
                                        } ${
                                          item.section.toString().split(",")
                                            .length !==
                                          idx + 1
                                            ? ","
                                            : ""
                                        } `}
                                      </React.Fragment>
                                    ))}
                                </>
                              ) : (
                                <>
                                  {(item.page || item.section) && (
                                    <>
                                      {`${
                                        item.page ? "p." + item.page + " " : ""
                                      }${
                                        item.section && "sec." + item.section
                                      }`}
                                    </>
                                  )}
                                </>
                              )}

                              {") "}
                            </>
                          )}
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            sx={{ width: "100%", wordWrap: "break-word" }}
                          >
                            {index + 1 + ". Website: "}
                            {item.title ? item.title : item?.url}
                            {item.author && " by " + item.author}
                            {(item.page || item.section) &&
                              `. (${item.page ? "p." + item.page + " " : ""}${
                                item.section && "sec." + item.section
                              })`}
                          </Typography>
                        </>
                      )}
                    </a>
                  </Typography>
                );
              })}
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <a href="https://EntheoGPT.com/" target="_blank" rel="noreferrer">
              <img
                src="/images/image 7.png"
                alt="EntheoGPT"
                className="max-w-[171px] max-h-[73px]"
              />
            </a>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            Close
          </Button>
          <Button
            variant="filled"
            className={`font-bold ${
              theme.palette.mode === "dark" && "!bg-[#f3f6ff] !text-[#1a2035]"
            }`}
            onClick={() => {
              handleMailSources(questionId);
            }}
          >
            Email this result
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ShowSources;
