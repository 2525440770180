import { Box } from "@mui/material";

import ContentEdit from "../components/chat/content-edit";
import ContentContainer from "../components/chat/content-container";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MagicMycQResponse from "../components/faqs/MagicMycQResponse";
import ReactGA from "react-ga4";
import EditChatFooter from "./Chat Settings/EditChatFooter";
import { updateChatStreaming } from "../store/messages/actions";
import { httpMushPro } from "../services/http-common";
import { useMutation } from "react-query";
import { setIsSubscriptionAvailable } from "../store/dataFlow/actions";
import { Dialog, DialogBody } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const Chat = () => {
  const dispatch = useDispatch();
  const [showSuggestions, setShowSuggestions] = useState(true);
  const { loading } = useSelector((state: any) => state.msg);
  const [isMaximizedChat, setIsMaximizedChat] = useState(false);
  const { isStreaming } = useSelector((state: any) => state.msg);
  const { sidebarWeb } = useSelector((state: any) => state.dataFlow);

  const handleStopStreaming = () => {
    dispatch(updateChatStreaming(false));
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "EntheoGPT by The Entheology Project",
    });
  }, []);

  const checkSubscriptionStatus = useMutation(
    () => {
      return httpMushPro.post("stripe/subscription-status", {});
    },
    {
      onSuccess: ({ data }: any) => {
        dispatch(setIsSubscriptionAvailable(data.subscription));
      },
      onError: (error: any) => {
        console.error(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    checkSubscriptionStatus.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-230px)]  md:max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-205px)] xl:max-h-[calc(100vh-205px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <ContentContainer
          showSuggestions={showSuggestions}
        />
      </Box>
      <Box
        sx={{
          position: { xs: "fixed", lg: sidebarWeb ? "fixed" : "absolute" },
          bottom: 0,
          minHeight: "90px",
          width: "-webkit-fill-available",
          paddingRight: "40px",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box sx={{ padding: "0px" }}>
          <EditChatFooter />
        </Box>
        <Box>
          {!loading && !isStreaming && (
            <>
              <MagicMycQResponse
                setShowSuggestions={setShowSuggestions}
              />
            </>
          )}
          <ContentEdit
            isMaximizedChat={isMaximizedChat}
            setIsMaximizedChat={setIsMaximizedChat}
            handleStopStreaming={handleStopStreaming}
            setShowSuggestions={setShowSuggestions}
          />
        </Box>
      </Box>
      <Dialog
        size="xl"
        open={isMaximizedChat}
        handler={() => {
          setIsMaximizedChat(!isMaximizedChat);
        }}
      >
        <DialogBody>
          <span className="flex justify-end items-center mb-2 cursor-pointer">
            <XMarkIcon
              className="w-5 h-5 "
              onClick={() => {
                setIsMaximizedChat(!isMaximizedChat);
              }}
            />
          </span>
          <ContentEdit
            isMaximizedChat={isMaximizedChat}
            setIsMaximizedChat={setIsMaximizedChat}
            handleStopStreaming={handleStopStreaming}
            setShowSuggestions={setShowSuggestions}
          />
        </DialogBody>
      </Dialog>
    </>
  );
};

export default Chat;
