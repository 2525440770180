import { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import AllSources from "./AllSources";
import Drawer from "react-bottom-drawer";
import Chat from "./chat";

function EntheoGPT() {
  const [showModal, setShowModal] = useState(false);
  const { selectedMessageRefs } = useSelector((state) => state.dataFlow);

  useEffect(() => {
    const handleToggleReferences = () => {
      if (window.innerWidth < 768) {
        setShowModal(true);
      }
    };
    handleToggleReferences();
  }, [selectedMessageRefs]);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} md={selectedMessageRefs ? 8 : 12}>
          <Chat />
        </Grid>
        {selectedMessageRefs && (
          <Grid
            item
            md={4}
            className="d-none d-md-block bg-white border border-l"
          >
            <AllSources />
          </Grid>
        )}
        {/* <EditChatFooter /> */}
      </Grid>

      <Drawer
        isVisible={!!(selectedMessageRefs && showModal)}
        onClose={() => setShowModal(false)}
      >
        <AllSources />
      </Drawer>
    </Container>
  );
}

export default EntheoGPT;
