import { useTheme } from "@mui/material";
import React from "react";
import { ColorContext } from "../theme/ColorContext";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { IoIosArrowDown } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

export const SwitchModeButton = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorContext);

  return (
    <>

      <Menu
        placement="bottom-end"
        dismiss={{
          itemPress: true,
        }}
      >
        <MenuHandler>
          <div className="flex justify-start items-center gap-2 w-auto ">
            {theme.palette.mode[0].toUpperCase() +
              theme.palette.mode.slice(1).toLowerCase()}
            <IoIosArrowDown fontSize="small" />
          </div>
        </MenuHandler>
        <MenuList className="!z-[9999] p-0">
          {["Dark", "Light"].map((itm, idx) => (
            <MenuItem
              style={{
                backgroundColor: theme.palette.mode === "dark" ? "#4a4e61" : "",
                color: theme.palette.mode === "dark" ? "#ffffff" : "",
                borderRadius: theme.palette.mode === "dark" ? "0px" : "",
                borderTop:
                  theme.palette.mode === "dark" && idx !== 0
                    ? "1px solid white"
                    : "",
              }}
              onClick={() => {
                colorMode.toggleColorMode(itm.toLowerCase());
              }}
            >
              <span className="flex justify-between items-center gap-2  cursor-pointer  ">
                {itm}
                {theme.palette.mode === itm.toLowerCase() && <FaCheck />}
              </span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};
