import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";


import Header from "./home/Header";
import Footer from "./home/Footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../KeyCloackServices/UserService";
import { Container, Divider, Stack, Typography } from "@mui/material";
import { bgGradient } from "../utils/cssStyles";
import { styled, alpha } from "@mui/material/styles";

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
  }),
  position: "relative",
  overflow: "hidden",
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Inter", "sans-serif"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".7vw",
          width: "10vw",
          fontSize: "1vw",
          fontWeight: "700",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "50vw",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "50vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        paragraph: true,
        mb: "1.5vw",
      },
    },
  },
});

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      navigate("/chat");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <StyledRoot>
          <div className="bg-[#f7f7f7] py-8 flex justify-center items-center">
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0",
              }}
            >
              Privacy Policy
            </Typography>
          </div>{" "}
          <Container className="bg-white">
            <Stack spacing={3} sx={{ p: 5, borderRadius: 2 }}>
              <Stack spacing={2}>
                <Typography variant="subtitle1">
                  <strong>Last Updated:</strong> 9/4/2024
                </Typography>

                <Typography variant="body1">
                  Entheology Project Inc. (“we,” “our,” “us”) is committed to
                  protecting your privacy and ensuring the security of your
                  data. This AI Privacy Policy explains how we collect, use,
                  store, and safeguard your information when you interact with
                  <strong>EntheoGPT.com</strong> (the “Product”), our AI-driven
                  chatbot platform. By using <strong>EntheoGPT.com</strong>, you
                  agree to the terms outlined in this Privacy Policy.
                </Typography>
                <Typography variant="body1">
                  If you do not agree with these terms, please refrain from
                  using <strong>EntheoGPT.com</strong>.
                </Typography>
              </Stack>

              <Divider sx={{ borderStyle: "dashed" }} />

              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  1. Introduction
                </Typography>

                <Typography variant="body1">
                  <strong>EntheoGPT.com</strong>, a product of{" "}
                  <strong>Entheology Project Inc.</strong>, offers AI-powered
                  chatbots that help users (“you,” “your”) engage with digital
                  coaching services, get assistance, and access other
                  functionalities. As a service provider that values user
                  privacy, we prioritize the protection of your personal
                  information, particularly in compliance with{" "}
                  <strong>HIPAA</strong> and other relevant regulations. This
                  Privacy Policy outlines our approach to collecting, using, and
                  safeguarding data while providing you with valuable AI-driven
                  services.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  2. Information We Collect
                </Typography>

                <Typography variant="body1">
                  We collect different types of information when you interact
                  with <strong>EntheoGPT.com</strong>:
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  2.1 Personal Information
                </Typography>
                <Typography variant={"body1"}>
                  Personal information refers to data that identifies you
                  directly or indirectly, such as:
                </Typography>
                <ul>
                  <li>
                    <strong>Name, Email address, and Phone number</strong>{" "}
                    stored securely on our{" "}
                    <strong>KeyCloak server at iam.entheo.info</strong>.
                  </li>
                  <li>
                    Payment information, if applicable (e.g., for paid
                    services).
                  </li>
                  <li>
                    Any identifying information you voluntarily provide during
                    chatbot interactions.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  2.2 Interaction Data
                </Typography>
                <Typography variant={"body1"}>
                  When using <strong>EntheoGPT.com</strong>, we may collect
                  information from your interactions, including:
                </Typography>
                <ul>
                  <li>
                    User prompts (e.g., questions or statements posed to the
                    AI).
                  </li>
                  <li>
                    Bot-generated responses and conversation history
                    (temporarily processed, anonymized, or paraphrased for
                    privacy).
                  </li>
                  <li>
                    User intents, feedback, or ratings of the AI responses.
                  </li>
                  <li>
                    Meta-information about interactions, such as timestamps and
                    session length.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  2.3 Non-Personal Information
                </Typography>
                <Typography variant={"body1"}>
                  This includes non-identifiable information such as:
                </Typography>
                <ul>
                  {[
                    "IP addresses",
                    "Device information",
                    "Browser type",
                    "Operating system",
                    "Referring URLs",
                  ].map((itm: any) => (
                    <li>{itm}</li>
                  ))}
                </ul>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  3. How We Use Your Information
                </Typography>

                <Typography variant="body1">
                  We use your information to deliver and improve the
                  functionality of <strong>EntheoGPT.com</strong>:
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  3.1 Personal Information
                </Typography>

                <ul>
                  <li>
                    <strong>Storage on KeyCloak:</strong> Your Name, Email
                    address, and Phone number are securely stored on our
                    <strong>KeyCloak server at iam.entheo.info</strong> to
                    authenticate and manage your account.
                  </li>
                  <li>
                    To communicate service-related updates or responses to
                    inquiries.
                  </li>
                  <li>
                    To manage billing and transactions (if required for paid
                    services).
                  </li>
                  <li>
                    To comply with legal requirements and enforce our{" "}
                    <strong>Terms of Service</strong>.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  3.2 Interaction Data
                </Typography>
                <ul>
                  <li>
                    <strong>Anonymization: </strong> Before storing or
                    processing interaction data, we ensure that all{" "}
                    <strong>Personally Identifiable Information (PII)</strong>{" "}
                    and <strong>Protected Health Information (PHI)</strong> are
                    stripped out, anonymized, or paraphrased.
                  </li>
                  <li>
                    <strong>Metadata Usage:</strong> We collect anonymized
                    metadata (such as user intent and sentiment analysis) for
                    the purposes of improving AI responses, fine-tuning models,
                    and providing coaches with valuable insights.
                  </li>
                  <li>
                    <strong>Response Analytics:</strong> We use interaction data
                    to analyze user satisfaction, improve response quality, and
                    troubleshoot agent performance.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  3.3 Non-Personal Information
                </Typography>

                <ul>
                  {[
                    "To improve platform functionality and user experience.",
                    "o diagnose and address technical issues.",
                  ].map((itm: any) => (
                    <li>{itm}</li>
                  ))}
                </ul>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  4. How We Ensure HIPAA Compliance
                </Typography>

                <Typography variant="body1">
                  As{" "}
                  <strong>
                    <strong>EntheoGPT.com</strong>
                  </strong>{" "}
                  may involve health-related coaching or interactions, we
                  implement measures to ensure full compliance with{" "}
                  <strong> HIPAA</strong>:
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  4.1 Removal of PHI and PII
                </Typography>

                <ul>
                  <li>
                    We automatically remove PHI and PII from user conversations
                    before storing interaction data for analytics or fine-tuning
                    purposes. This ensures that no sensitive health information
                    is retained during the data storage process.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  4.2 Business Associate Agreements (BAAs)
                </Typography>
                <ul>
                  <li>
                    Any third-party vendor or service provider that may handle
                    PHI (e.g., cloud storage providers) is required to sign a
                    <strong>Business Associate Agreement (BAA)</strong>, legally
                    obligating them to protect your data in compliance with
                    HIPAA regulations.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  4.3 Encryption in Transit and at Rest
                </Typography>

                <ul>
                  <li>
                    We use end-to-end encryption to protect all data in transit
                    between the user and <strong>EntheoGPT.com</strong> servers.
                    This encryption includes the use of{" "}
                    <strong>TLS (Transport Layer Security)</strong> to ensure
                    that any data exchanged is secure.
                  </li>
                  <li>
                    All sensitive data, including PHI, is encrypted while stored
                    (at rest), ensuring it cannot be accessed by unauthorized
                    parties.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  4.4 User Consent and Control
                </Typography>

                <ul>
                  <li>
                    Users are provided with clear consent options to opt out of
                    data sharing for model improvement or analytics purposes.
                    This allows for full transparency in how your data is
                    handled.
                  </li>
                </ul>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  5. How We Share Your Information
                </Typography>

                <Typography variant="body1">
                  We do not sell, rent, or trade your personal information.
                  However, we may share your information in the following
                  limited cases:
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.1 With Accredited Laboratories or Coaches
                </Typography>

                <ul>
                  <li>
                    If applicable, we may share anonymized, paraphrased, or
                    summarized interaction data with coaches or partner labs to
                    enhance user experiences or troubleshoot issues. No personal
                    data or PHI is shared unless required by law or authorized
                    by you.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.2 With Third-Party Service Providers
                </Typography>
                <Typography variant="body1">
                  We may share your data with trusted third-party service
                  providers for:
                </Typography>
                <ul>
                  {[
                    "Payment processing",
                    "Cloud storage",
                    "Technical support and maintenance",
                  ].map((itm: any) => (
                    <li>{itm}</li>
                  ))}
                </ul>
                <Typography variant="body1">
                  These providers are required to comply with privacy and data
                  protection laws and are bound by confidentiality agreements.{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  5.3 Legal Obligations
                </Typography>
                <Typography variant="body1">
                  We may share your data to comply with applicable laws,
                  regulations, or legal processes, including responding to court
                  orders or regulatory requests.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  6. Data Security and Encryption
                </Typography>

                <Typography variant="body1">
                  We are committed to securing your data through
                  industry-standard practices:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  6.1 Encryption
                </Typography>
                <ul>
                  <li>
                    <strong>Encryption in Transit:</strong> We use{" "}
                    <strong>TLS encryption</strong> to protect all data
                    transmitted between your device and our servers. This
                    ensures that any data shared through{" "}
                    <strong>EntheoGPT.com</strong> is encrypted and protected
                    from unauthorized interception.
                  </li>
                  <li>
                    <strong>Encryption at Rest:</strong> All personal data,
                    including Name, Email address, and Phone number stored on
                    the <strong>KeyCloak server at iam.entheo.info</strong>, as
                    well as interaction logs, are encrypted while stored in our
                    databases, ensuring maximum security for any stored
                    information.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  6.2 Role-Based Access Controls
                </Typography>
                <ul>
                  <li>
                    Access to personal and anonymized interaction data is
                    restricted to authorized personnel who require access to
                    perform their duties. Coaches, developers, and
                    administrators have limited access based on their roles.
                  </li>
                </ul>
                <Typography
                  variant="h6"
                  sx={{ color: "#434343", fontWeight: "bold" }}
                >
                  6.3 Auditing and Monitoring
                </Typography>
                <ul>
                  <li>
                    We regularly audit and monitor our systems to ensure that
                    data handling practices comply with privacy and security
                    standards, including HIPAA.
                  </li>
                </ul>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  7. Data Retention
                </Typography>

                <Typography variant="body1">
                  We retain personal and anonymized data only for as long as
                  necessary to fulfill the purposes outlined in this policy or
                  as required by law. PHI is retained only as long as necessary
                  for health-related services and is deleted once it is no
                  longer needed.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  8. Your Data Privacy Rights
                </Typography>
                <Typography variant="body1">
                  Depending on your jurisdiction, you may have the following
                  rights regarding your personal information:
                </Typography>
                <ul>
                  <li>
                    <strong>Access:</strong> You can request a copy of the
                    personal data we have collected about you.
                  </li>
                  <li>
                    <strong>Correction:</strong> You can request that we correct
                    any inaccurate or incomplete information.
                  </li>
                  <li>
                    <strong>Deletion:</strong> You can request that we delete
                    your personal data, subject to legal retention obligations.
                  </li>
                  <li>
                    <strong>Restriction:</strong> You can request restrictions
                    on how we process your data.
                  </li>
                  <li>
                    <strong>Data Portability:</strong> You can request a copy of
                    your data in a structured, machine-readable format.
                  </li>
                </ul>{" "}
                <Typography variant="body1">
                  To exercise any of these rights, please contact us at
                  <a href="mailto:admin@entheo.info">admin@entheo.info</a>.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  9. Cookies and Tracking Technologies
                </Typography>

                <Typography variant="body1">
                  We may use cookies and other tracking technologies to improve
                  your experience on <strong>EntheoGPT.com</strong>. These
                  cookies help us understand how users interact with the
                  platform, track preferences, and enhance functionality.
                </Typography>
                <Typography variant="body1">
                  You can manage cookie preferences through your browser
                  settings. However, disabling cookies may impact your
                  experience on the platform.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  10. Changes to This Privacy Policy
                </Typography>

                <Typography variant="body1">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for legal reasons. If changes are
                  made, we will update the "Last Updated" date at the top of
                  this page. Your continued use of{" "}
                  <strong>EntheoGPT.com</strong> after any changes constitutes
                  acceptance of the updated Privacy Policy.
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Stack spacing={1}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  11. Contact Us
                </Typography>

                <Typography variant="body1">
                  If you have any questions about this Privacy Policy or how
                  your data is handled, please contact us at:
                </Typography>
                <Typography variant="body1">
                  <strong> Entheology Project Inc.</strong> <br />
                  2021 Fillmore St PMB 2035
                  <br />
                  San Francisco, CA 94115 <br />
                  Phone: 415-390-6250 <br />
                  Email:{" "}
                  <a href="mailto:admin@entheo.info">admin@entheo.info</a>{" "}
                  <br />
                  <a
                    href="/privacy-policy"
                    className="text-[#000080] font-bold"
                  >
                    https://entheogpt.com/privacy-policy
                  </a>
                  <br />
                  EIN: 88-1953133
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </StyledRoot>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default PrivacyPolicy;
