import { useEffect, useState } from "react";
import {
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import * as messages from "../../../services/messages";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserService from "../../../KeyCloackServices/UserService";
import http from "../../../services/http-common";

function LegalReSearchDetails(props) {
  const { id } = props;
  const { isSubscriptionAvailable } = useSelector((state) => state.dataFlow);
  const [scrapedContent, setScrapedContent] = useState(null);
  const [selectedLegalTopics, setSelectedLegalTopics] = useState();

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const getDocDetails = useMutation(
    (docId) => {
      return http.post(`law-docs/find-by-id`, { id: docId });
    },
    {
      onSuccess: ({ data }) => {
        setScrapedContent(data.doc);
        setSelectedLegalTopics(
          data.doc?.metadata?.legal_topics?.map((topic) => ({
            value: topic,
            label: topic,
          })) || []
        );
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const getDocDetailFunc = () => {
    if (id === null) return false;
    getDocDetails.mutate(id);
  };

  useEffect(() => {
    getDocDetailFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {scrapedContent ? (
        <Container className="px-0">
          <div className="flex justify-center">
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="text-lg font-bold text-center">
                      {scrapedContent?.metadata?.document_title
                        ? scrapedContent?.metadata?.document_title
                        : "N/A"}
                      {scrapedContent?.metadata?.legal_citation && (
                        <Typography variant="body2" color="textSecondary">
                          <br />
                          {scrapedContent?.metadata?.legal_citation}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Summary</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.summary
                        ? scrapedContent?.metadata?.summary
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Filing Status</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.filing_status && (
                        <span className="border-2 border-[#7a6fbe] rounded-full px-2 py-1 text-xs">
                          {scrapedContent?.metadata?.filing_status
                            ? scrapedContent?.metadata?.filing_status
                            : "N/A"}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Court/Agency name</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.court_or_agency_name
                        ? scrapedContent?.metadata?.court_or_agency_name
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Jurisdiction Court</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.jurisdiction?.court
                        ? scrapedContent?.metadata?.jurisdiction?.court
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Jurisdiction Level</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.jurisdiction?.level
                        ? scrapedContent?.metadata?.jurisdiction?.level
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Jurisdiction State</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.jurisdiction?.state
                        ? scrapedContent?.metadata?.jurisdiction?.state
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Docket Number</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.docket_number
                        ? scrapedContent?.metadata?.docket_number
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Procedural Posture</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.procedural_posture
                        ? scrapedContent?.metadata?.procedural_posture
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Legal Topics</strong>
                      </Typography>
                      <br />
                      <div className="flex flex-wrap gap-2">
                        <ul className="horizontal-list flex flex-wrap gap-2">
                          {selectedLegalTopics?.map((option) => (
                            <Chip
                              size="small"
                              label={option.label}
                              className="me-1"
                            />
                          ))}
                        </ul>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Document type</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.document_type
                        ? scrapedContent?.metadata?.document_type
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Filing Date</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.filing_date
                        ? scrapedContent?.metadata?.filing_date
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Decision Date</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.decision_date
                        ? scrapedContent?.metadata?.decision_date
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Parties Involved</strong>
                      </Typography>
                      <br />
                      <div className="flex flex-col gap-y-1 mx-2">
                        <div className="text-sm">
                          <span className="">•</span>
                          <strong>Defendant:</strong>{" "}
                          {
                            scrapedContent?.metadata?.parties_involved
                              ?.defendant
                          }
                        </div>
                        <div className="text-sm">
                          <span className="">•</span>
                          <strong>Plaintiff:</strong>{" "}
                          {
                            scrapedContent?.metadata?.parties_involved
                              ?.plaintiff
                          }
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Outcome Decision</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.outcome_decision
                        ? scrapedContent?.metadata?.outcome_decision
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        <strong>Related Cases</strong>
                      </Typography>
                      <br />
                      {scrapedContent?.metadata?.related_cases?.map(
                        (caseItem, index) => (
                          <div key={index} className="mb-2">
                            <div>
                              <strong>
                                {index + 1 + ". " + caseItem.case_name}
                              </strong>
                            </div>
                            <div className="ps-3">
                              <strong>Citation:</strong> {caseItem.citation}
                            </div>
                          </div>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {(UserService.getRole()?.roles?.includes("admin") ||
              isSubscriptionAvailable) && (
              <Button
                href={scrapedContent?.originalUrl}
                download={scrapedContent?.title ? scrapedContent?.title : "N/A"}
                variant="contained"
                color="primary"
              >
                Download Metadata
              </Button>
            )}
          </div>
        </Container>
      ) : (
        <span>Loading ...</span>
      )}
    </>
  );
}

export default LegalReSearchDetails;
