import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { setSessionExpired, useMainController } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import { httpMushPro } from "../../../services/http-common";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import { showGPTReview } from "../../../store/bot/actions";

const ReviewGpt = () => {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [isReviewSubmit, setIsReviewSubmit] = useState(false);
  const { botDetails, isReviewingingGPT } = useSelector((state) => state.bot);
  const [dispatchHttp] = useMainController();
  const dispatch = useDispatch();

  const toogleOpen = () => {
    isReviewingingGPT
      ? dispatch(showGPTReview(false))
      : dispatch(showGPTReview(true));
  };

  const sendReview = useMutation(
    () => {
      return httpMushPro.post(
        `user/review-bot`,
        {
          rating: rating,
          botId: botDetails?.id,
        },
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        setIsReviewSubmit(true);
        dispatch(showGPTReview(false));
        toogleOpen();
        setLoading(false);
        toast.success(data.message);
        setRating(0);
      },
      onError: (err) => {
        console.log(err);
        setIsReviewSubmit(false);
        setLoading(false);
        setRating(0);
      },
    }
  );

  const submitReview = () => {
    setLoading(true);
    sendReview.mutate();
  };

  return (
    <>
      <Dialog size="sm" open={isReviewingingGPT} handler={toogleOpen}>
        <DialogHeader className="flex justify-between items-center">
          <span className="text-base text-[#000000]">Leave Feedback</span>
          <MdOutlineClose className="cursor-pointer" onClick={toogleOpen} />
        </DialogHeader>
        <DialogBody divider className="grid  ">
          <div className="mb-4">
            {isReviewSubmit && (
              <div className="text-base font-semibold text-center mb-2 text-[#000000]">
                Thanks for your feedback.
              </div>
            )}

            <div
              className={`text-sm text-[#000000] flex justify-center ${
                isReviewSubmit && "text-center"
              } `}
            >
              {isReviewSubmit
                ? `We really appreciate you helping to improve the "${
                    botDetails?.botName + " Bot"
                  }" experience for everyone!`
                : "How would you rate this GPT?"}
            </div>
            {!isReviewSubmit && (
              <>
                <div className="flex flex-row justify-center items-center gap-1 p-4 sm:p-6">
                  {[1, 2, 3, 4, 5].map((number, idx) => (
                    <span
                      key={idx}
                      onClick={() => {
                        setRating(number);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={`
                      w-9 h-9 cursor-pointer hover:text-yellow-700  ${
                        number <= rating
                          ? "fill-yellow-700  text-yellow-700"
                          : "fill-none text-blue-gray-500"
                      }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                        ></path>
                      </svg>
                    </span>
                  ))}
                </div>
                <Button
                  disabled={loading || !rating > 0}
                  size="sm"
                  className={`w-full normal-case rounded-full ${
                    !rating > 0 && "!cursor-not-allowed"
                  }`}
                  onClick={submitReview}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ReviewGpt;
