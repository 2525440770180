import { toast } from "react-toastify";
import * as actionTypes from "../constants";

const initialState: {
  dataFlow: string;
  sidebarWeb: boolean;
  model: any;
  totalStatistics: any;
  tokensSummary: any;
  noOfRefsChat: any;
  chatSearchFilters: any;
  selectedMessageRefs: any;
  quickResponses: [];
  isSubscriptionAvailable: boolean;
} = {
  sidebarWeb: true,
  dataFlow: "main",
  chatSearchFilters: JSON.parse(
    sessionStorage.getItem("chatControl") || "null"
  ),
  model: null,
  totalStatistics: null,
  selectedMessageRefs: null,
  tokensSummary: null,
  noOfRefsChat: null,
  isSubscriptionAvailable: false,
  quickResponses: [],
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.CHANGE_DATA_FLOW:
      return {
        ...prevState,
        dataFlow: action.payload,
      };
    case actionTypes.MODEL_SELECTION:
      return {
        ...prevState,
        model: action.payload,
      };
    case actionTypes.SAVE_SUGGESTED_QUESTIONS:
      return {
        ...prevState,
        quickResponses: action.payload,
      };
    case actionTypes.SHOW_SIDEBAR_WEB:
      return {
        ...prevState,
        sidebarWeb: action.payload,
      };
    case actionTypes.GET_STATISTICS:
      return {
        ...prevState,
        totalStatistics: action.payload,
      };
    case actionTypes.IS_SUBSCRIPTION_AVAILABLE:
      return {
        ...prevState,
        isSubscriptionAvailable: action.payload,
      };
    case actionTypes.SET_TOKENS_SUMMARY:
      return {
        ...prevState,
        tokensSummary: action.payload,
      };
    case actionTypes.SET_REFERENCES_CHAT:
      return {
        ...prevState,
        noOfRefsChat: action.payload,
      };
    case actionTypes.SELECTED_MSG_REFS:
      return {
        ...prevState,
        selectedMessageRefs: action.payload,
      };
    case actionTypes.SEARCH_FILTERS:
      toast.success("Saved search fitlers.");
      return {
        ...prevState,
        chatSearchFilters: action.payload,
      };
    default:
      return prevState;
  }
};

export default reducer;
