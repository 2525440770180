import * as React from "react";
import { Box, Divider, Drawer, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { setSessionExpired, useMainController } from "../../../context";
import { Spinner } from "@material-tailwind/react";
import PricingTable from "../../../pages/home/Pricing/PricingTable";

export default function Subscription({ sidebar = false }) {
  const [dispatchHttp] = useMainController();
  const [allProducts, setAllProducts] = React.useState(null);
  const [open, setOpen] = React.useState(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const getProducts = useMutation(
    () => {
      return http.get("stripe/products", null, setSessionExpired, dispatchHttp);
    },
    {
      onSuccess: ({ data }) => {
        setAllProducts(data.products);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );




  

  React.useEffect(() => {
    getProducts.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <nav
        aria-label=""
        className={`main mailbox folders ${
          sidebar && "!absolute !py-2 bottom-0 w-full"
        }`}
      >
        <Divider />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: { xs: "190px", lg: "206px", xl: "206px" },
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <LoadingButton
              size="small"
              onClick={handleOpen}
              sx={{
                mt: 2,
                borderRadius: "10px",
                height: "50.65px",
                mb: "20px",
                width: "205px",
              }}
              type="submit"
              variant="contained"
            >
              Upgrade plan
            </LoadingButton>
          </Box>
        </Box>
      </nav>
      <Drawer
        anchor={"bottom"}
        open={open}
        className="!w-full"
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: "100vw", height: "100vh", paddingX: "10px" }}
          role="presentation"
        >
          <div className="relative grid grid-cols-[1fr_auto_1fr] px-6 py-4 md:pb-10 md:pt-[4.5rem]">
           
            <button
              onClick={toggleDrawer(false)}
              className="justify-self-end text-token-text-primary opacity-50 transition hover:opacity-75 md:absolute md:right-6 md:top-6"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-lg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.63603 5.63604C6.02656 5.24552 6.65972 5.24552 7.05025 5.63604L12 10.5858L16.9497 5.63604C17.3403 5.24552 17.9734 5.24552 18.364 5.63604C18.7545 6.02657 18.7545 6.65973 18.364 7.05025L13.4142 12L18.364 16.9497C18.7545 17.3403 18.7545 17.9734 18.364 18.364C17.9734 18.7545 17.3403 18.7545 16.9497 18.364L12 13.4142L7.05025 18.364C6.65972 18.7545 6.02656 18.7545 5.63603 18.364C5.24551 17.9734 5.24551 17.3403 5.63603 16.9497L10.5858 12L5.63603 7.05025C5.24551 6.65973 5.24551 6.02657 5.63603 5.63604Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          </div>
          <Grid container spacing={2}>
            {allProducts ? (
              <PricingTable plans={allProducts} />
            ) : (
              <Spinner />
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}
