import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { PiCirclesFourBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useMutation } from "react-query";
import { httpMushPro } from "../../services/http-common";
import {
  searchFilters,
  setIsSubscriptionAvailable,
} from "../../store/dataFlow/actions";
import { usStates } from "../../lib/util";
import { deleteChatHistory } from "../../store/messages/actions";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
  Checkbox,
  Button,
  Input,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FunnelIcon, TrashIcon } from "@heroicons/react/24/solid";
import { FaLock } from "react-icons/fa";

const FilterSearch = () => {
  const dispatch = useDispatch();
  const { chatSearchFilters, isSubscriptionAvailable } = useSelector(
    (state) => state.dataFlow
  );
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openMenuSetting, setOpenMenuSetting] = useState(false);
  const [selected, setSelected] = useState({
    jurisdiction: "",
    documentType: [],
    legalTopics: [],
    state: "",
    courtLevel: "",
    outcomeDecision: "",
  });

  const [chatControl, setChatControl] = useState({
    jurisdiction: "",
    science: true,
    enthe_wisdom: false,
    legal: false,
    numberOfRefs: 5,
    state: "",
    courtLevel: "",
    documentType: [],
    legalTopics: [],
    filing_date: { from: "", to: "" },
    decision_date: { from: "", to: "" },
    outcomeDecision: "",
  });

  const toggleCategory = () => {
    setOpen(!open);
  };

  const onSubmit = () => {
    toggleCategory();
    sessionStorage.setItem("chatControl", JSON.stringify(chatControl));
    dispatch(searchFilters(chatControl));
  };

  const handleSelectChange = (field, selectedGroup) => {
    if (Array.isArray(selectedGroup)) {
      const values = selectedGroup.map((group) => group.value);
      setSelected((prev) => ({
        ...prev,
        [field]: selectedGroup,
      }));
      handleChange(field, values);
    } else {
      setSelected((prev) => ({
        ...prev,
        [field]: selectedGroup,
      }));
      handleChange(field, selectedGroup.value);
    }
  };
  const handleChange = (field, value) => {
    setChatControl((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const deleteHandler = () => {
    dispatch(deleteChatHistory());
  };

  const checkSubscriptionStatus = useMutation(
    () => {
      return httpMushPro.post("stripe/subscription-status", {});
    },
    {
      onSuccess: ({ data }) => {
        dispatch(setIsSubscriptionAvailable(data.subscription));
      },
      onError: (error) => {
        console.error(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    checkSubscriptionStatus.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chatSearchFilters) {
      setChatControl(chatSearchFilters);
      setSelected({
        outcomeDecision: chatSearchFilters?.outcomeDecision,
        science: chatSearchFilters?.science,
        enthe_wisdom: chatSearchFilters?.enthe_wisdom,
        legal: chatSearchFilters?.legal,
        numberOfRefs: chatSearchFilters?.numberOfRefs,
        jurisdiction: chatSearchFilters?.jurisdiction,
        legalTopics: chatSearchFilters?.legalTopics,
        documentType: chatSearchFilters?.documentType,
      });
    }
  }, [chatSearchFilters]);

  return (
    <React.Fragment>
      <Menu placement="top-start">
        <MenuHandler>
          <IconButton
            className="bg-white !rounded-md "
            aria-label="Open Filter Modal"
            sx={{ border: "1px solid #A2A5C6" }}
            onClick={() => setOpenMenuSetting(!openMenuSetting)}
          >
            <PiCirclesFourBold className="text-xl" />
          </IconButton>
        </MenuHandler>
        <MenuList>
          <MenuItem
            onClick={toggleCategory}
            className=" flex justify-start items-center gap-2"
          >
            <FunnelIcon className=" !text-base w-[20px]" />

            <span>Advance Search</span>
          </MenuItem>
          <MenuItem
            onClick={deleteHandler}
            className=" flex justify-start items-center gap-2"
          >
            <TrashIcon className="text-red-500 !text-base w-[20px]" />
            <Typography variant="small" className="!text-sm text-red-500">
              Clear Chat
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>

      <Dialog size="md" open={open} onClose={toggleCategory}>
        <DialogBody className="max-h-[30rem] overflow-y-auto">
          <Typography variant="h6" color="blue-gray">
            Search Filter
          </Typography>
          <hr className="mb-2" />
          <form onSubmit={onSubmit}>
            <div className="mb-1 flex flex-col ">
              <div className="flex justify-between items-center">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="whitespace-nowrap me-20"
                >
                  Number of References
                </Typography>
                <Input
                  containerProps={{ className: "min-w-[72px] max-w-[72px]" }}
                  placeholder="000"
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900 text-center"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={chatControl.numberOfRefs}
                  onChange={(e) => handleChange("numberOfRefs", e.target.value)}
                />
              </div>
              <Checkbox
                label="Science Research Papers"
                checked={chatControl.science}
                onChange={() => handleChange("science", !chatControl.science)}
              />
              {isSubscriptionAvailable ? (
                <Checkbox
                  label="Entheo Wisdom Research Papers"
                  checked={chatControl.enthe_wisdom}
                  onChange={() =>
                    handleChange("enthe_wisdom", !chatControl.enthe_wisdom)
                  }
                />
              ) : (
                <>
                  <div
                    className="flex justify-between items-center"
                    onClick={() => setOpenAlert(!openAlert)}
                  >
                    <Checkbox disabled label="Entheo Wisdom Research Papers" />
                    <FaLock className="w-4 h-4 text-[#f44336]" />
                  </div>
                </>
              )}
              {isSubscriptionAvailable ? (
                <Checkbox
                  label="Legal Research Papers"
                  checked={chatControl.legal}
                  onChange={() => handleChange("legal", !chatControl.legal)}
                />
              ) : (
                <>
                  <div
                    className="flex justify-between items-center"
                    onClick={() => setOpenAlert(!openAlert)}
                  >
                    <Checkbox disabled label="Legal Research Papers" />
                    <FaLock className="w-4 h-4 text-[#f44336]" />
                  </div>
                  {openAlert && (
                    <div className=" relative flex flex-col w-full text-base font-regular rounded-lg border border-red-500 text-red-700  justify-center items-center p-2  gap-2">
                      <span className="text-sm text-center">
                        The EntheoLaw Library is available to Pro subscribers
                        only. Unlock full access to legal documents on
                        entheogens by upgrading to Pro.
                      </span>
                      <Button size="sm" variant="outlined">
                        Upgrade
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>{" "}
            {chatControl.legal && (
              <>
                <div className="grid grid-cols-1 mb-2">
                  <div
                    className={`grid ${
                      chatControl.jurisdiction === "State" ||
                      chatControl.jurisdiction === "local"
                        ? "sm:grid-cols-2 grid-cols-1"
                        : "grid-cols-1"
                    }  gap-4 `}
                  >
                    <div>
                      <Typography
                        color="blue-gray"
                        variant="h6"
                        className="mb-1"
                      >
                        Jurisdiction
                      </Typography>
                      <Select
                        value={selected.jurisdiction}
                        onChange={(value) =>
                          handleSelectChange("jurisdiction", value)
                        }
                        options={[
                          {
                            value: "Federal",
                            label: "Federal",
                          },
                          {
                            value: "State",
                            label: "State",
                          },
                          {
                            value: "courtLevel",
                            label: "Court Level",
                          },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                    {chatControl.jurisdiction === "State" && (
                      <div>
                        <Typography
                          color="blue-gray"
                          variant="h6"
                          className="mb-1"
                        >
                          State
                        </Typography>
                        <Select
                          value={selected.state}
                          onChange={(value) =>
                            handleSelectChange("state", value)
                          }
                          options={usStates}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    )}
                    {chatControl.jurisdiction === "courtLevel" && (
                      <div className="grid gap-1">
                        <Typography
                          color="blue-gray"
                          variant="h6"
                          className="mb-1"
                        >
                          Court Level
                        </Typography>
                        <Select
                          value={selected.courtLevel}
                          onChange={(value) =>
                            handleSelectChange("courtLevel", value)
                          }
                          options={[
                            {
                              value: "Federal Courts",
                              label: "Federal Courts",
                            },
                            {
                              value: "State Supreme Courts",
                              label: "State Supreme Courts",
                            },
                            {
                              value: "City/County Courts",
                              label: "City/County Courts",
                            },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 mb-2 gap-4">
                  <div>
                    <Typography color="blue-gray" variant="h6" className="mb-1">
                      Document Type
                    </Typography>
                    <Select
                      value={selected.documentType}
                      isMulti
                      onChange={(value) =>
                        handleSelectChange("documentType", value)
                      }
                      // onChange={handleSelectGroup}
                      // onChange={() => {
                      //   handleMulti();
                      // }}
                      options={[
                        {
                          value: "Case Law",
                          label: "Case Law",
                        },
                        {
                          value: "Statutes",
                          label: "Statutes",
                        },
                        {
                          value: "Regulations",
                          label: "Regulations",
                        },
                        {
                          value: "Administrative Decisions",
                          label: "Administrative Decisions",
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div>
                    <Typography color="blue-gray" variant="h6" className="mb-1">
                      Legal Topics
                    </Typography>
                    <Select
                      value={selected.legalTopics}
                      onChange={(value) =>
                        handleSelectChange("legalTopics", value)
                      }
                      isMulti
                      options={[
                        {
                          value: "Controlled Substances",
                          label: "Controlled Substances",
                        },
                        {
                          value: "Religious Exemptions",
                          label: "Religious Exemptions",
                        },
                        {
                          value: "FDA/DEA Regulations",
                          label: "FDA/DEA Regulations",
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </div>
                <div className="mb-2 gap-4">
                  <Typography color="blue-gray" variant="h6" className="mb-1">
                    Filing Date Range
                  </Typography>
                  <div className="flex sm:flex-row flex-col justify-start items-center  gap-2">
                    <Input
                      type="datetime-local"
                      value={chatControl.filing_date.from}
                      onChange={(e) =>
                        handleChange("filing_date", {
                          ...chatControl.filing_date,
                          from: e.target.value,
                        })
                      }
                    />
                    {" - "}
                    <Input
                      type="datetime-local"
                      value={chatControl.filing_date.to}
                      onChange={(e) =>
                        handleChange("filing_date", {
                          ...chatControl.filing_date,
                          to: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="grid gap-1">
                  <Typography color="blue-gray" variant="h6" className="mb-1">
                    Decision Date Range
                  </Typography>
                  <div className="flex sm:flex-row flex-col items-center gap-2">
                    <Input
                      type="datetime-local"
                      value={chatControl.decision_date.from}
                      onChange={(e) =>
                        handleChange("decision_date", {
                          ...chatControl.decision_date,
                          from: e.target.value,
                        })
                      }
                    />
                    {" - "}
                    <Input
                      className=""
                      type="datetime-local"
                      value={chatControl.decision_date.to}
                      onChange={(e) =>
                        handleChange("decision_date", {
                          ...chatControl.decision_date,
                          to: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="grid gap-1">
                  <Typography color="blue-gray" variant="h6" className="mb-1">
                    Outcome/Decision
                  </Typography>
                  <Select
                    value={selected.outcomeDecision}
                    onChange={(value) =>
                      handleSelectChange("outcomeDecision", value)
                    }
                    options={[
                      {
                        value: "Plaintiff Prevails",
                        label: "Plaintiff Prevails",
                      },
                      {
                        value: "Defendant Prevails",
                        label: "Defendant Prevails",
                      },
                      {
                        value: "Settled",
                        label: "Settled",
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />{" "}
                </div>
              </>
            )}
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={toggleCategory}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="black" onClick={onSubmit}>
            <span>Save</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};

export default FilterSearch;
