import React, { useState } from "react";

import {
  Box,
  FormControl,
  TextField,
  FormLabel,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { httpMushPro } from "../../../services/http-common";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import TimezoneSelect from "react-timezone-select";

function Appointment() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useSelector((state) => state.auth);
  const { botDetails } = useSelector((state) => state.bot);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({
    seekerId: user?.id,
    proId: botDetails?.kcId,
    startDate: "",
    endDate: "",
    title: "",
    description: "",
    location: "",
    meetingLink: "",
    timezone: "Etc/GMT",
  });

  const fixAppointment = useMutation(
    () => {
      return httpMushPro.post(`user/scheduling/appointment`, content);
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Appointment Create Successfully!");
        handleOpen();
        setContent({
          seekerId: user?.id,
          proId: botDetails?.kcId,
          startDate: "",
          endDate: "",
          title: "",
          description: "",
          location: "",
          meetingLink: "",
          timezone: "Etc/GMT",
        });
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleOpen = () => {
    setOpen(!open);
  };

  const onChangeContent = (e) => {
    if (loading) return false;
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    fixAppointment.mutate();
  };

  const onChangeTimezone = (e) => {
    setContent({ ...content, timezone: e.value });
  };
  return (
    <>
      <span
        variant="text"
        className="!w-md mb-1 underline text-[#000080] font-semibold"
        onClick={handleOpen}
      >
        Click Here
      </span>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: { xs: "0px", lg: "24px" },
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="flex justify-between items-center"
        >
          Schedule Meeting with Coach
          <CloseIcon onClick={handleOpen} className="cursor-pointer" />
        </DialogTitle>

        <Divider />
        <DialogContent>
          <Box
            sx={{
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <FormControl fullWidth sx={{ flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Title</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="title"
                type="text"
                validate={{
                  required: { value: true },
                }}
                value={content.title}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Description</FormLabel>
              <TextField
                size="small"
                minRows={3}
                maxRows={5}
                multiline={true}
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="description"
                type="textarea"
                validate={{
                  required: { value: true },
                }}
                value={content.description}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>
                Meeting Platform
              </FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="location"
                type="text"
                validate={{
                  required: { value: true },
                }}
                value={content.location}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Meeting Link</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="meetingLink"
                type="text"
                validate={{
                  required: { value: true },
                }}
                value={content.meetingLink}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Timezone</FormLabel>
              <TimezoneSelect
                value={content.timezone}
                onChange={(e) => onChangeTimezone(e)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>Start Date</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="startDate"
                type="datetime-local"
                validate={{
                  required: { value: true },
                }}
                value={content.startDate}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, flexGrow: 1, mr: 10 }}>
              <FormLabel sx={{ marginBottom: "8px" }}>End Date</FormLabel>
              <TextField
                size="small"
                onChange={(e) => onChangeContent(e)}
                fullWidth
                name="endDate"
                type="datetime-local"
                validate={{
                  required: { value: true },
                }}
                value={content.endDate}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column-reverse" },
                justifyContent: { sm: "end", xs: "start" },
                alignItems: "center",
              }}
            >
              <LoadingButton
                size="small"
                sx={{
                  mt: 2,
                  borderRadius: "10px",
                  height: "50.65px",
                  mb: "20px",
                  width: "205px",
                }}
                type="submit"
                variant="contained"
                onClick={onSubmit}
                disabled={loading}
              >
                Request Appointment
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Appointment;
