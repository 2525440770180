import { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Dialog,
  DialogBody,
  Typography,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { cleanMessages, deleteChatHistory } from "../../store/messages/actions";
import WarningIcon from "@mui/icons-material/Warning";
import { Divider } from "@mui/material";
import { getSettingData } from "../sidebar";
import CloseIcon from "@mui/icons-material/Close";
import { logout } from "../../store/auth/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import NumberOfToken from "./NumberOfToken";
import TokenSummary from "../SettingsPages/Tokens/TokenSummary";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/20/solid";
import { plansDetails } from "../../utils/PlanDetails";
import PricingTable from "../../pages/home/Pricing/PricingTable";
import { RiMessage3Line } from "react-icons/ri";
import { AllSettings } from "./AllSettings";

export function SettingsModal() {
  const dispatch = useDispatch();
  const [selectedTitle, setSelectedTitle] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const sections = getSettingData();
  const location = useLocation();

  const deleteHandler = () => {
    dispatch(deleteChatHistory());
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpen(!open);
    setIsProfile(false);
  };

  const MenuOptions = [
    {
      title: "Settings Menu",
      option: <AllSettings />,
    },
    {
      title: "Tokens Summary",
      option: <TokenSummary />,
    },
    {
      title: "Create GPT",
      option: <PricingTable plans={plansDetails} />,
    },
  ];

  const handleMenu = (text) => {
    setIsProfile(true);
    setSelectedTitle(text);
    setOpen(!open);
  };

  const onClickHandler = (path) => {
    if (typeof path === "string") {
      return () => {
        navigate(path);
      };
    } else {
      return () => {
        // alert("here");
        dispatch(cleanMessages());
        dispatch(logout());
      };
    }
  };
  const handleLogout = () => {
    dispatch(cleanMessages());
    dispatch(logout());
  };

  return (
    <>
      <Menu placement="top-end">
        <MenuHandler>
          <MoreVertIcon className="cursor-pointer" />
        </MenuHandler>

        <MenuList className="!w-auto p-0 overflow-hidden">
          <MenuItem
            key="tokens-summary"
            className="flex justify-start items-center gap-3 "
            onClick={() => handleMenu("Tokens Summary")}
          >
            <div>
              {user?.attributes?.avatar ? (
                <img
                  src={
                    user?.attributes?.avatar ? user?.attributes?.avatar[0] : ""
                  }
                  alt={user.firstName}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <MdAccountCircle className="w-8 h-8 !text-xl" />
              )}
            </div>
            <div className="user-info">
              <h6 className="font-bold">{user.firstName}</h6>
              <NumberOfToken />
            </div>
          </MenuItem>
          <Divider />
          {location.pathname === "/chat" && (
            <MenuItem
              key="chat-menu-item"
              onClick={handleOpen}
              className="flex justify-start items-center gap-3 "
            >
              <TrashIcon className="text-red-500 !text-base w-[22px]" />
              <Typography variant="small" className="!text-sm text-red-500">
                Clear Chat
              </Typography>
            </MenuItem>
          )}

          <Divider />

          <MenuItem
            onClick={() => handleMenu("Create GPT")}
            className="flex justify-start items-center gap-3 "
          >
            <RiMessage3Line className="w-5 h-5 !text-xl" />
            {/* {_item.icon} */}
            <Typography variant="small" className="!text-sm ">
              Create GPT
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenu("Settings Menu");
            }}
            className="flex justify-start items-center gap-3 "
          >
            <Cog6ToothIcon className="w-[21px]" />
            {/* {_item.icon} */}
            <Typography variant="small" className="!text-sm ">
              Settings
            </Typography>
          </MenuItem>
          <Divider />

          {sections.map((_item) => {
            return (
              <>
                {_item?.item?.map((_item, idx) => (
                  <>
                    {_item.title === "Profile" ? (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          handleMenu(_item.title);
                        }}
                        className="flex justify-start items-center gap-3 "
                      >
                        {/* <DeleteIcon className="text-red-500 !text-base" /> */}
                        {_item.icon}
                        <Typography variant="small" className="!text-sm ">
                          {_item.title}
                        </Typography>
                      </MenuItem>
                    ) : (
                      <>
                        {_item.title !== "Email" && (
                          <>
                            {_item.title === "Logout" && <Divider />}
                            <MenuItem
                              key={idx}
                              onClick={() => {
                                _item?.title === "Logout"
                                  ? handleLogout()
                                  : onClickHandler(_item.path);
                              }}
                              className="flex justify-start items-center gap-3 "
                            >
                              {/* <DeleteIcon className="text-red-500 !text-base" /> */}
                              {_item.icon}
                              <a
                                rel="noreferrer"
                                href={_item?.link ? _item?.link : "#"}
                                target={_item?.link ? "_blank" : "_self"}
                              >
                                <Typography
                                  variant="small"
                                  className="!text-sm "
                                >
                                  {_item.title}
                                </Typography>
                              </a>
                              {_item?.setting && _item?.setting}
                            </MenuItem>
                          </>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            );
          })}
        </MenuList>
      </Menu>

      <Dialog
        size={
          isProfile && selectedTitle === "Data Control"
            ? "xs"
            : selectedTitle === "Create GPT"
            ? "xl"
            : "md"
        }
        open={open}
        handler={handleOpen}
      >
        {selectedTitle !== "Create GPT" ? (
          <>
            {isProfile ? (
              <DialogHeader className="flex justify-between items-center text-xl">
                Settings
                <CloseIcon onClick={handleOpen} className="cursor-pointer" />
              </DialogHeader>
            ) : (
              <DialogHeader>
                <Typography variant="h5" color="blue-gray">
                  Your Attention is Required!
                </Typography>
              </DialogHeader>
            )}
          </>
        ):                 <DialogHeader className="flex justify-between items-center text-xl">
          Upgrade 
          <CloseIcon onClick={handleOpen} className="cursor-pointer" />
        </DialogHeader>
        }

        <DialogBody
          divider
          className={`relative ${
            isProfile
              ? "  max-h-[50rem] overflow-x-hidden "
              : "grid place-items-center gap-4 "
          } `}
        >
          {isProfile ? (
            <>
              {MenuOptions.map(
                (item) => item.title === selectedTitle && item.option
              )}
            </>
          ) : (
            <>
              <Typography color="black" variant="lead">
                <WarningIcon className="text-red-500" /> Confirmation
              </Typography>
              <Typography className="text-center font-normal">
                Are you sure you want to delete your chat?
              </Typography>
              <DialogFooter className="space-x-2">
                <Button
                  size="sm"
                  variant="outlined"
                  color="blue-gray"
                  className="normal-case font-medium"
                  onClick={handleOpen}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="gradient"
                  onClick={deleteHandler}
                  className=" normal-case font-medium"
                >
                  Delete
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogBody>
      </Dialog>
    </>
  );
}
