export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_CHAT_BOT_MESSAGE = "ADD_CHAT_BOT_MESSAGE";
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const GET_ANSWER_REQUEST = "GET_ANSWER_REQUEST";
export const GET_ANSWER_REQUEST_SUCCESS = "GET_ANSWER_REQUEST_SUCCESS";
export const GET_ANSWER_REQUEST_ERROR = "GET_ANSWER_REQUEST_ERROR";

export const CHAT_STREAMING = "CHAT_STREAMING";

export const GET_ANSWER_AGAIN_REQUEST = "GET_ANSWER_AGAIN_REQUEST";
export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS";

export const POST_SIGNIN_REQUEST = "POST_SIGNIN_REQUEST";
export const POST_RESET_PASSWORD_REQUEST = "POST_RESET_PASSWORD_REQUEST";
export const POST_RESET_PASSWORD_REQUEST_SUCCESS =
  "POST_RESET_PASSWORD_REQUEST_SUCCESS";
export const POST_FORGET_PASSWORD_REQUEST = "POST_FORGET_PASSWORD_REQUEST";
export const POST_FORGET_PASSWORD_REQUEST_SUCCESS =
  "POST_FORGET_PASSWORD_REQUEST_SUCCESS";
export const POST_SIGNIN_REQUEST_SUCCESS = "POST_SIGNIN_REQUEST_SUCCESS";
export const POST_GOOGLE_SIGNIN_REQUEST = "POST_GOOGLE_SIGNIN_REQUEST";

export const POST_GOOGLE_SIGNIN_REQUEST_SUCCESS =
  "POST_GOOGLE_SIGNIN_REQUEST_SUCCESS";

export const LOGOUT = "LOGOUT";

export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_REQUEST_SUCCESS = "GET_USER_INFO_REQUEST_SUCCESS";

export const GET_CHAT_HISTORY_REQUEST = "GET_CHAT_HISTORY_REQUEST";
export const GET_CHAT_HISTORY_REQUEST_SUCCESS =
  "GET_CHAT_HISTORY_REQUEST_SUCCESS";

export const DELETE_CHAT_HISTORY_REQUEST = "DELETE_CHAT_HISTORY_REQUEST";
export const DELETE_CHAT_HISTORY_REQUEST_SUCCESS =
  "DELETE_CHAT_HISTORY_REQUEST_SUCCESS";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_REQUEST_SUCCESS = "REGISTER_USER_REQUEST_SUCCESS";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";

export const UPLOAD_FILES_REQUEST = "UPLOAD_FILES_REQUEST";

export const UPLOAD_CONTRIBUTE_FILES_REQUEST =
  "UPLOAD_CONTRIBUTE_FILES_REQUEST";

export const GET_MYDOCS_REQUEST = "GET_MYDOCS_REQUEST";
export const GET_MYDOCS_REQUEST_SUCCESS = "GET_MYDOCS_REQUEST_SUCCESS";

export const CONVERT_DOCUMENTS_PRIVATE_REQUEST =
  "CONVERT_DOCUMENTS_PRIVATE_REQUEST";
export const DELETE_DOCUMENTS_REQUEST = "DELETE_DOCUMENTS_REQUEST";

export const GET_PDFS_REQUEST = "GET_PDFS_REQUEST";
export const GET_PDFS_REQUEST_SUCCESS = "GET_PDFS_REQUEST_SUCCESS";

export const REGSITER_URL_REQUEST = "REGSITER_URL_REQUEST";

export const REGSITER_CONTRIBUTE_URL_REQUEST =
  "REGSITER_CONTRIBUTE_URL_REQUEST";

export const GET_MYWEBSITES_REQUEST = "GET_MYWEBSITES_REQUEST";
export const GET_MYWEBSITES_REQUEST_SUCCESS = "GET_MYWEBSITES_REQUEST_SUCCESS";
export const ADD_SUGGESTION_QUESTIONS = "ADD_SUGGESTION_QUESTIONS";

export const DELETE_WEBSITES_REQUEST = "DELETE_WEBSITES_REQUEST";

export const GET_WEBSITES_REQUEST = "GET_WEBSITES_REQUEST";
export const GET_WEBSITES_REQUEST_SUCCESS = "GET_WEBSITES_REQUEST_SUCCESS";

export const SEND_HELP_EMAIL_REQUEST = "SEND_HELP_EMAIL_REQUEST";
export const SEND_HELP_EMAIL_REQUEST_SUCCESS =
  "SEND_HELP_EMAIL_REQUEST_SUCCESS";
export const POST_Delete_Profile_REQUEST = "POST_Delete_Profile_REQUEST";
export const POST_Delete_Profile_SUCCESS = "POST_Delete_Profile_SUCCESS";

export const REGISTER_NEWSLETER_REQUEST = "REGISTER_NEWSLETER_REQUEST";
export const GET_CHAT_ANSWER_REQUEST = "GET_CHAT_ANSWER_REQUEST";

export const EMBEDDING_HISTORY_REQUEST = "EMBEDDING_HISTORY_REQUEST";
export const EMBEDDING_HISTORY_REQUEST_SUCCESS =
  "EMBEDDING_HISTORY_REQUEST_SUCCESS";

export const REGISTER_BOT_REQUEST = "REGISTER_BOT_REQUEST";
export const REGISTER_BOT_REQUEST_SUCCESS = "REGISTER_BOT_REQUEST_SUCCESS";
export const UPDATE_BOT_REQUEST = "UPDATE_BOT_REQUEST";
export const UPDATE_BOT_MYC_REQUEST = "UPDATE_BOT_MYC_REQUEST";
export const ADD_BOT_MESSAGE = "ADD_BOT_MESSAGE";

export const GET_BOT_DETAILS_REQUEST = "GET_BOT_DETAILS_REQUEST";
export const GET_BOT_DETAILS_REQUEST_SUCCESS =
  "GET_BOT_DETAILS_REQUEST_SUCCESS";
export const GET_BOT_DATA_MYC_REQUEST = "GET_BOT_DATA_MYC_REQUEST";
export const GET_BOT_DATA_MYC_SUCCESS = "GET_BOT_DATA_MYC_SUCCESS";
export const GET_BOT_ANSWER_REQUEST_SUCCESS = "GET_BOT_ANSWER_REQUEST_SUCCESS";
export const GET_BOT_ANSWER_REQUEST_ERROR = "GET_BOT_ANSWER_REQUEST_ERROR";

export const CLEAR_MESSAGES_HISTORY = "CLEAR_MESSAGES_HISTORY";
export const CLEAR_BOT_MESSAGES_HISTORY = "CLEAR_BOT_MESSAGES_HISTORY";
export const GET_MYC_QUICK_RESPONSES_REQUEST =
  "GET_MYC_QUICK_RESPONSES_REQUEST";
export const GET_MYC_QUICK_RESPONSES_REQUEST_SUCCESS =
  "GET_MYC_QUICK_RESPONSES_REQUEST_SUCCESS";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const GET_BOT_QUICK_REPLYS_REQUEST = "GET_BOT_QUICK_REPLYS_REQUEST";
export const GET_BOT_QUICK_REPLYS_REQUEST_SUCCESS =
  "GET_BOT_QUICK_REPLYS_REQUEST_SUCCESS";
export const GET_BOT_QUICK_RESPONSES_REQUEST =
  "GET_BOT_QUICK_RESPONSES_REQUEST";

export const UPGRADE_TO_PRO_REQUEST = "UPGRADE_TO_PRO_REQUEST";
export const CHANGE_DATA_FLOW = "CHANGE_DATA_FLOW";

export const MODEL_SELECTION = "MODEL_SELECTION";

export const SAVE_SUGGESTED_QUESTIONS = "SAVE_SUGGESTED_QUESTIONS";

export const SHOW_SIDEBAR_WEB = "SHOW_SIDEBAR_WEB";
export const GET_STATISTICS = "GET_STATISTICS";
export const SET_TOKENS_SUMMARY = "SET_TOKENS_SUMMARY";
export const SET_REFERENCES_CHAT = "SET_REFERENCES_CHAT";
export const IS_SUBSCRIPTION_AVAILABLE = "IS_SUBSCRIPTION_AVAILABLE";
export const SELECTED_MSG_REFS = "SELECTED_MSG_REFS";
export const SEARCH_FILTERS = "SEARCH_FILTERS";

// ----------------------------------------------------------
export const GET_KC_USER_INFO = "GET_KC_USER_INFO";
export const LOGIN_BY_KC = "LOGIN_BY_KC";
export const LOGIN_BY_KC_SUCCESS = "LOGIN_BY_KC_SUCCESS";

export const GET_ALL_GPTS_REQUEST = "GET_ALL_GPTS_REQUEST";
export const GET_ALL_GPTS_SUCCESS = "GET_ALL_GPTS_SUCCESS";

export const GET_ALL_FAV_GPTS_REQUEST = "GET_ALL_FAV_GPTS_REQUEST";
export const GET_ALL_FAV_GPTS_SUCCESS = "GET_ALL_FAV_GPTS_SUCCESS";

export const ADD_FAV_GPTS_REQUEST = "ADD_FAV_GPTS_REQUEST";
export const ADD_FAV_GPTS_SUCCESS = "ADD_FAV_GPTS_SUCCESS";

export const GET_BOT_CHAT_HISTORY_REQUEST = "GET_BOT_CHAT_HISTORY_REQUEST";
export const GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS =
  "GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS";

export const SELECTED_BOT_DATA = "SELECTED_BOT_DATA";

export const SAVE_BOT_DATA = "SAVE_BOT_DATA";
export const GET_BOT_DATA_REQUEST = "GET_BOT_DATA_REQUEST";
export const GET_BOT_DATA_REQUEST_SUCCESS = "GET_BOT_DATA_REQUEST_SUCCESS";
export const GET_BOT_ANSWER_REQUEST = "GET_BOT_ANSWER_REQUEST";
export const GET_BOT_ANSWER_AGAIN_REQUEST = "GET_BOT_ANSWER_AGAIN_REQUEST";

export const DELETE_BOT_CHAT_HISTORY_REQUEST =
  "DELETE_BOT_CHAT_HISTORY_REQUEST";
export const DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS =
  "DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS";

export const IS_REPORTING_GPT = "IS_REPORTING_GPT";
export const SHOW_GPT_DETAILS = "SHOW_GPT_DETAILS";
export const SHOW_GPT_REVIEW = "SHOW_GPT_REVIEW";

export const BOT_CHAT_STREAMING = "BOT_CHAT_STREAMING";
