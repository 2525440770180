import { Menu, MenuHandler } from "@material-tailwind/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { httpMushPro } from "../../services/http-common";
import { setSessionExpired, useMainController } from "../../context";
import { toast } from "react-toastify";
import { Chip } from "@mui/material";
import { updateModelSelection } from "../../store/dataFlow/actions";

const SelectModel = () => {
  const dispatch = useDispatch();
  const dispatchHttp = useMainController();
  const { model } = useSelector((state) => state.dataFlow);


  const getAllMModels = useMutation(
    () => {
      return httpMushPro.post(
        `/llms/getall`,
        {
          isEnabled: true,
        },
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: () => {
        // setAllModel(data?.records);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {},
      onSettled: () => {},
    }
  );
  useEffect(() => {
    getAllMModels.mutate();
    if (!model) {
      dispatch(
        updateModelSelection({
          name: "GPT-4o",
          type: "gpt",
          status: "public",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu
        placement="bottom-end"
        dismiss={{
          itemPress: true,
        }}
      >
        <MenuHandler>
          <div className="flex justify-start items-center gap-2 ">
            <Chip
              variant="outlined"
              size="sm"
              label={model ? model?.name : ""}
              className="w-[min-content] normal-case !bg-[#000080] !text-white rounded-full "
            />
            {/* <IoIosArrowDown fontSize="small" /> */}
          </div>
        </MenuHandler>

        {model && model?.status === "private" ? (
          <span className="rounded-md  !text-xs bg-[orange] outline-[orange] !text-[white] px-1 font-semibold">
            Private
          </span>
        ) : (
          <span className="rounded-md  !text-xs outline-[green] bg-[green] !text-[white] px-1 font-semibold">
            Public
          </span>
        )}
      </Menu>
    </>
  );
};

export default SelectModel;
