import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnswer,
  getMycQuickResponses,
  updateChatStreaming,
} from "../../store/messages/actions";
import { useTheme } from "@mui/material";
import { saveSuggestedQuestions } from "../../store/dataFlow/actions";

interface MagicMycQResponseProps {
  setShowSuggestions: (showSuggestions: boolean) => void;
}

const MagicMycQResponse: FC<MagicMycQResponseProps> = ({
  setShowSuggestions,
}) => {
  const theme = useTheme();
  const { model, quickResponses, chatSearchFilters } = useSelector(
    (state: any) => state.dataFlow
  );
  const [randomSuggestion, setRandomSuggestion] = useState([]);
  const [lastQuery, setLastQuery] = useState(null);
  const { loading, messages } = useSelector((state: any) => state.msg);
  const dispatch = useDispatch();

  useEffect(() => {
    quickResponses?.length > 0 && randomQA(quickResponses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickResponses]);

  useEffect(() => {
    if (!quickResponses.length) {
      dispatch(getMycQuickResponses(quickResponseCallBack));
    } else {
      randomQA(quickResponses);
    }
    messages.length > 2 && setLastQuery(messages[1].message);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const quickResponseCallBack = (data: any) => {
    if (data.status === "success") {
      dispatch(saveSuggestedQuestions(data.returnData.data.quickReplies));
      randomQA(data.returnData.data.quickReplies);
    } else {
      console.log("One bug happens on server side. Please try again later.");
    }
  };

  const [lastSelectedIds, setLastSelectedIds] = useState<number[]>([]);
  // Function to select 3 random objects
  const selectRandomObjects = (data: any, lastIds: number[]): any => {
    const selectedIds = new Set(lastIds); // Track previously selected objects
    const uniqueSelection: any = [];

    // Randomly pick objects that weren't selected last time
    while (uniqueSelection.length < 3) {
      const randomIndex = Math.floor(Math.random() * data.length);
      const selectedObject = data[randomIndex];

      if (
        !selectedIds.has(selectedObject.id) ||
        lastQuery !== selectedObject.question ||
        !uniqueSelection.has(selectedObject.id)
      ) {
        uniqueSelection.push(selectedObject);
        selectedIds.add(selectedObject.id); // Mark this object as selected
      }
    }

    return uniqueSelection;
  };

  const randomQA = (data: any) => {
    const newSelection = selectRandomObjects(data, lastSelectedIds);
    setRandomSuggestion(newSelection);
    // Update the last selected IDs
    setLastSelectedIds(newSelection.map((obj: { id: any }) => obj.id));
  };

  const onSubmit = (query: any) => {
    if (query !== "") {
      setShowSuggestions(false);
      dispatch(
        getAnswer({
          finalChat: query,
          llm: model?.name ? model?.name?.toLocaleLowerCase() : "gpt-4o",
          type: model?.type ? model?.type : "gpt",
          science: chatSearchFilters?.science,
          enthe_wisdom: chatSearchFilters?.enthe_wisdom,
          legal: chatSearchFilters?.legal,
          ref: chatSearchFilters?.numOfRefs ? chatSearchFilters?.numOfRefs : 5,
          filters: {
            jurisdiction: {
              federal: chatSearchFilters?.jurisdiction?.federal
                ? chatSearchFilters?.jurisdiction?.federal
                : "",
              state: chatSearchFilters?.state ? chatSearchFilters?.state : "",
              courtLevel: chatSearchFilters?.courtLevel
                ? chatSearchFilters?.courtLevel
                : "",
            },
            document_type: chatSearchFilters?.documentType
              ? chatSearchFilters?.documentType
              : "",
            decision_date: {
              from: chatSearchFilters?.decision_date?.from
                ? chatSearchFilters?.decision_date?.from
                : "",
              to: chatSearchFilters?.decision_date?.to
                ? chatSearchFilters?.decision_date?.to
                : "",
            },
            filing_date: {
              from: chatSearchFilters?.filing_date?.from
                ? chatSearchFilters?.filing_date?.from
                : "",
              to: chatSearchFilters?.filing_date?.to
                ? chatSearchFilters?.filing_date?.to
                : "",
            },
          },
        })
      );
      dispatch(updateChatStreaming(true));
    }
  };

  const firstFiveWords = (text: string, count: number) => {
    let words = text.split(" ");
    return words.slice(0, count).join(" ");
  };
  const lastWords = (text: string, count: number) => {
    let words = text.split(" ");
    return words.slice(count, words.length).join(" ");
  };
  return (
    <div
      className="flex justify-start gap-2 py-2 w-full overflow-x-scroll scrollbar-none  whitespace-nowrap "
      style={{ scrollbarWidth: "none" }}
    >
      {randomSuggestion?.map((item: any, idx: any) => {
        return (
          <span
            className={` ${
              theme?.palette.mode === "light"
                ? "bg-[#f2f2f7] opacity-70 "
                : "bg-[#464c65] opacity-80 text-white "
            } `}
            onClick={() => onSubmit(item.question ? item.question : item)}
            key={idx}
          >
            <button
              className={`truncate h-auto p-[10px] text-start text-ellipsis overflow-hidden ${
                theme?.palette.mode === "light"
                  ? "bg-[#f2f2f7] opacity-70 "
                  : "bg-[#464c65] opacity-80 text-white "
              } inline-flex items-center justify-center text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-80 bg-zinc-900 text-zinc-50 hover:bg-zinc-800/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90 rounded-md px-3 whitespace-nowrap font-normal shadow-none group-[.cb-dark]:bg-zinc-800 group-[.cb-dark]:text-white group-[.cb-dark]:hover:bg-zinc-700 group-[.cb-light]:bg-zinc-200/50 group-[.cb-light]:text-black group-[.cb-light]:hover:bg-zinc-200`}
              type="button"
            >
              <p className="truncate sm:w-auto w-72">
                <strong>
                  {item.question
                    ? firstFiveWords(
                        item.question,
                        item.question.split(" ")?.length < 6 ? 3 : 5
                      )
                    : firstFiveWords(item, item.question)}
                </strong>
                <br />
                <p className="truncate sm:w-auto w-72">
                  {item.question
                    ? lastWords(
                        item.question,
                        item.question.split(" ")?.length < 6 ? 3 : 5
                      )
                    : lastWords(item, item.question)}
                </p>
                {/* {item.question ? item.question : item} */}
              </p>
            </button>
          </span>
        );
      })}
    </div>
  );
};

export default MagicMycQResponse;
