import React, { useEffect, useState } from "react";

import {
  FormControl,
  FormLabel,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import "react-day-picker/style.css";

import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { httpMushPro } from "../../../services/http-common";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import TimezoneSelect from "react-timezone-select";
import { setSessionExpired, useMainController } from "../../../context";
import { Button, Input, Typography } from "@material-tailwind/react";
import { convertTo12HourFormat } from "./BookAppointment/data";
import { checkToday, weekOfTheDays } from "../../../lib/util";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function AppointmentBot() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dispatch] = useMainController();
  const [selectedTime, setSelectedTime] = useState(null);
  const [availabileSlots, setAvailabileSlots] = useState(null);
  const [allWorkingDays, setAllWorkingDays] = useState(null);
  const [availability, setAvailability] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { botDetails } = useSelector((state) => state.bot);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({
    botId: botDetails?.id,
    seekerId: user?.id,
    proId: botDetails?.kcId,
    title: "Appointment",
    date: "",
    duration: "",
    slot: {
      startTime: "",
      endTime: "",
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const getProAvailability = useMutation(
    () => {
      return httpMushPro.get(
        `user/scheduling/availability-by-id/${botDetails?.coach?.id}`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        // Generate dates for two months starting from today
        setContent({
          ...content,
          duration: data.availability[0]?.duration,
        });

        setAvailabileSlots(data.availability);
        const workingDays = data.availability
          .filter((item) => item.slots[0] && item.slots[0].startTime !== null)
          .map((item) => item.dayOfWeek);

        setAllWorkingDays(workingDays);
      },
      onError: () => {
        toast.error("Fetch data failed");
      },
    }
  );

  const generateWeekDate = (selectedDate) => {
    const today = new Date(selectedDate);
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek); // Set to the start of the week

    // Create an array to hold the working day dates
    const workingDayDates = [];

    // Loop through each day from today until the end of the month

    // Check if the day is a working
    for (let i = 0; i < 7; i++) {
      const current = new Date(startOfWeek);
      current.setDate(startOfWeek.getDate() + i);
      workingDayDates.push({
        date:
          current.getFullYear() +
          "-" +
          (current.getMonth() + 1) +
          "-" +
          current.getDate(),
        slots: availabileSlots?.find(
          (days) =>
            days.dayOfWeek ===
            current.toLocaleString("en-US", { weekday: "long" })
        ).slots,
      });
    }
    setAvailability(workingDayDates);
  };

  const availableDays = availability?.map((day) => new Date(day.date));

  useEffect(() => {
    selectedDate ? generateWeekDate(selectedDate) : getProAvailability.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const fixAppointment = useMutation(
    () => {
      return httpMushPro.post(`user/scheduling/appointment`, content);
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Appointment Create Successfully!");
        handleOpen();
        setContent({
          botId: botDetails?.id,
          seekerId: user?.id,
          proId: botDetails?.kcId,
          title: "Appointment",
          startDate: "",
          endDate: "",
          date: "",
          duration: "",
          slot: {
            startTime: "",
            endTime: "",
          },
          timezone: "Etc/GMT",
        });
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const handleOpen = () => {
    setOpen(!open);
  };
  const onSubmit = () => {
    fixAppointment.mutate();
  };

  const onChangeTimezone = (e) => {
    setContent({ ...content, timezone: e.value });
  };

  const generateNextWeek = (selectedDate) => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() + 7);
    setSelectedDate(date);
    generateWeekDate(date);
  };

  const generatePrevWeek = (selectedDate) => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() - 7);
    setSelectedDate(date);
    generateWeekDate(date);
  };

  return (
    <>
      <span
        className="!w-md mb-1 underline text-[#000080] font-semibold cursor-pointer"
        onClick={handleOpen}
      >
        Click Here
      </span>
      <Dialog
        open={open}
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: { xs: "0px", lg: "24px" },
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="flex justify-between items-center"
        >
          Schedule Meeting with Coach
          <CloseIcon onClick={handleOpen} className="cursor-pointer" />
        </DialogTitle>

        <Divider />
        <DialogContent>
          <div className="flex flex-col gap-8  bg-white">
            <div className=" flex justify-center flex-col md:flex-row gap-5 ">
              <div className="w-full flex flex-col justify-center items-center gap-2">
                <div className="flex flex-row justify-normal items-end gap-1">
                  <FormControl fullWidth sx={{ flexGrow: 1 }}>
                    <FormLabel>Timezone</FormLabel>
                    <TimezoneSelect
                      className="z-20"
                      labelStyle="original"
                      value={content.timezone}
                      onChange={(e) => onChangeTimezone(e)}
                    />
                  </FormControl>{" "}
                  <Input
                    type="date"
                    placeholder="Select Date"
                    containerProps={{ className: "min-w-[72px] max-w-[150px]" }}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    onChange={(e) => {
                      let date = new Date(e.target.value);
                      setSelectedDate(date);
                      setContent({
                        ...content,
                        date:
                          date.getFullYear() +
                          "-" +
                          (date.getMonth() + 1) +
                          "-" +
                          date.getDate(),
                        startDate: date,
                        endDate: date,
                      });
                      setSelectedTime(null); // Reset selected time when date changes
                    }}
                  />
                </div>
                {selectedDate && (
                  <>
                    {" "}
                    <div className="flex justify-center items-center gap-2">
                      <FormLabel
                        sx={{ marginBottom: "4px" }}
                        className="!font-bold !text-[#000080]"
                      >
                        {selectedDate?.getDate() +
                          " " +
                          selectedDate?.toLocaleString("default", {
                            month: "long",
                          }) +
                          " " +
                          selectedDate?.getFullYear()}
                      </FormLabel>
                    </div>
                    <div className="bg-[#ffffff] p-4 flex justify-start items-center  ">
                      <ChevronLeftIcon
                        width={"1.5rem"}
                        height={"1.5rem"}
                        className="cursor-pointer sm:me-3 me-0"
                        onClick={() => generatePrevWeek(selectedDate)}
                      />
                      {availableDays &&
                        availableDays.map((date) => {
                          const isPastDate = date < new Date();
                          const isSelectedDate =
                            selectedDate &&
                            date.getDate() === selectedDate.getDate();
                          return (
                            <div
                              className={`${
                                isSelectedDate
                                  ? "bg-[#000080] text-white"
                                  : isPastDate
                                  ? "text-mute "
                                  : ""
                              } ${
                                isPastDate ||
                                !allWorkingDays.includes(
                                  checkToday[date.getDay()]
                                )
                                  ? "cursor-not-allowed !bg-gray-300 !text-grey"
                                  : "cursor-pointer"
                              }    sm:text-base text-sm  flex flex-col justify-center items-center sm:p-3 p-1`}
                              onClick={() => {
                                if (
                                  !isPastDate ||
                                  !allWorkingDays.includes(
                                    checkToday[date.getDay()]
                                  )
                                ) {
                                  setSelectedDate(date);
                                  setContent({
                                    ...content,
                                    date:
                                      date.getFullYear() +
                                      "-" +
                                      (date.getMonth() + 1) +
                                      "-" +
                                      date.getDate(),
                                    startDate: date,
                                    endDate: date,
                                  });
                                  setSelectedTime(null); // Reset selected time when date changes
                                }
                              }}
                            >
                              <span>{weekOfTheDays[date.getDay()]}</span>
                              <span>{date?.getDate()}</span>
                            </div>
                          );
                        })}
                      <ChevronRightIcon
                        width={"1.5rem"}
                        height={"1.5rem"}
                        className="cursor-pointer sm:ms-3 ms-0"
                        onClick={() => generateNextWeek(selectedDate)}
                      />
                    </div>
                  </>
                )}
                {selectedDate && (
                  <div className="w-full h-full overflow-hidden md:overflow-scroll no-scrollbar">
                    <div className="mb-4">
                      <Typography variant="h5" className="mb-2 text-center">
                        Available Time Slots
                      </Typography>
                      <div className="flex flex-wrap justify-center gap-2">
                        {availabileSlots &&
                          availabileSlots
                            .find(
                              (days) =>
                                days.dayOfWeek ===
                                selectedDate?.toLocaleString("en-US", {
                                  weekday: "long",
                                })
                            )
                            .slots?.map((slot) => (
                              <>
                                {slot.startTime ? (
                                  <Button
                                    size="sm"
                                    key={slot}
                                    color="primary"
                                    disabled={slot.isBooked}
                                    variant={
                                      selectedTime === slot.startTime
                                        ? "filled"
                                        : "outlined"
                                    }
                                    className={
                                      selectedTime === slot.startTime
                                        ? "bg-black text-white "
                                        : " border-[#000080] text-[#000080]  w-auto "
                                    }
                                    onClick={() => {
                                      setSelectedTime(slot.startTime);
                                      setContent({
                                        ...content,
                                        slot: {
                                          startTime: slot.startTime,
                                          endTime: slot.endTime,
                                        },
                                      });
                                    }}
                                  >
                                    {convertTo12HourFormat(slot.startTime) +
                                      " - " +
                                      convertTo12HourFormat(slot.endTime)}
                                  </Button>
                                ) : (
                                  "No slots available"
                                )}
                              </>
                            ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {selectedTime && (
              <Button
                variant="gradient"
                color="#000080"
                disabled={loading}
                onClick={onSubmit}
                type="submit"
                className="w-full"
              >
                Submit Request
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AppointmentBot;
