import { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import parse from "html-react-parser";
// ** Import Type
import { FC } from "react";

import "./style.css";

// ** Import MUI
import {
  Grid,
  Typography,
  Box,
  Avatar,
  IconButton,
  Tooltip,
  useTheme,
  Chip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch, useSelector } from "react-redux";
import ShowSources from "./showSources";
import ResponseFeedback from "./ResponseFeedback";
import FormattedResponse from "../FormattedResponse";
import { textToHeaderCase } from "../../utils/TextToHeaderCase";
import { updateBotChatStreaming } from "../../store/bot/actions";
import ChatOverlaysBot from "../chat/OverLays/ChatOverlaysBot";

let isBotResponse = false;

interface HumanMessageProps {
  message: string;
}

export const HumanMessage: FC<HumanMessageProps> = ({ message }) => {
  const { user } = useSelector((state: any) => state.auth);
  const [clipboardText, setClipboardText] = useState("Click to copy");
  const theme = useTheme();

  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy");
    }, 200);
  };
  return (
    <Grid
      container
      justifyContent="end"
      sx={{
        marginTop: "13px",
        marginBottom: "13px",
        paddingLeft: { xs: "10px", md: "90px" },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", position: "relative" }}
        justifyContent="end"
        alignItems="end"
      >
        <Box
          sx={{ marginRight: { xs: "10px", md: "26px" }, position: "relative" }}
        >
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              backgroundColor: `${
                theme?.palette.mode === "light"
                  ? "background.userMessage"
                  : "#474b5d"
              }`,
              color: "white",
              padding: "18px 26px 18px 24px",
              maxWidth: "835px",
              borderRadius: "18px 18px 0px 18px",
              span: {
                whiteSpace: "inherit !important",
              },
            }}
          >
            {parse(message)}
            <Box
              className="bottom-3 right-0"
              sx={{
                // position: { xs: "inherit", md: "absolute" },
                // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                position: "absolute",
                display: "flex",

                // marginLeft: 0,
              }}
            >
              <CopyToClipboard
                text={message.replace(/<[^>]+>/g, "")}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied");
                }}
              >
                <Box>
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy">
                      <ContentCopyIcon
                        sx={{ height: "16px", width: "16px", color: "white" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CopyToClipboard>
            </Box>
          </Typography>
          <Box
            className={`triangle-down-human ${
              theme?.palette.mode === "dark" && "!border-t-[#474b5d]"
            } `}
          ></Box>
        </Box>
        <Avatar
          variant={theme?.palette.mode === "dark" ? "rounded" : "circular"}
          alt="Seeker"
          src={user.avatar ? user.avatar : "/images/seeker.png"}
          className="!rounded-full !bg-white"
          sx={{
            minWidth: 62,
            height: 62,
            display: { xs: "none", md: "block" },
          }}
        />
      </Grid>
      <Avatar
        variant={theme?.palette.mode === "dark" ? "rounded" : "circular"}
        alt="Seeker"
        src={user.avatar ? user.avatar : "/images/seeker.png"}
        className=" !rounded-full !bg-white"
        sx={{
          width: 50,
          height: 50,
          display: { xs: "block", md: "none" },
          mt: "10px",
        }}
      />
    </Grid>
  );
};
interface ReferItem {
  page?: number;
  section?: number;
  name?: string;
  url: string;
  author: string;
  publication_date: string;
  title: string;
}

interface Refer {
  [key: string]: ReferItem[];
}

interface BotMessageProps {
  showSuggestions: boolean;
  questionId: string;
  message: string;
  feedback: any;
  model: any;
  tokens: any;
  trigger?: any;
  messageIndex: number;
  question: string;
  refer?: Refer;
}

export const BotMessage: FC<BotMessageProps> = ({
  showSuggestions,
  questionId,
  feedback,
  model,
  trigger,
  tokens,
  message,
  refer,
  question,
  messageIndex,
}) => {
  const dispatch = useDispatch();
  const [clipboardText, setClipboardText] = useState("Click to copy");
  const [currentWord, setCurrentWord] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const { botDetails, isBotStreaming } = useSelector((state: any) => state.bot);
  const theme = useTheme();

  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy");
    }, 200);
  };
  useEffect(() => {
    if (currentWord >= message.length || !isBotStreaming) {
      setIsComplete(true);
      return;
    }
    let currentIndex: number = 0;
    const interval = setInterval(() => {
      setCurrentWord(
        (prevWord) =>
          prevWord +
          message.split(" ")[currentIndex].length +
          message.split(" ")[currentIndex - 1].length
      );
      currentIndex = 5 + currentIndex;
      if (!isBotStreaming) {
        return () => clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWord, message]);

  useEffect(() => {
    let count = 0;
    if (isBotResponse) {
      count++;
    }
    if (count === 1 && messageIndex == 0 && isComplete) {
      dispatch(updateBotChatStreaming(false));
      count--;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);

  const getReferences = () => {
    let refers: any = "";
    // refer &&
    //   refer.length > 0 &&
    //   refer.map((_item, idx) => {
    //     return (refers =
    //       refers +
    //       (idx + 1) +
    //       ". " +
    //       (_item.name ? "Doc: " : "Website: ") +
    //       _item.title +
    //       " by " +
    //       _item.author +
    //       ((_item.page || _item.section) &&
    //         `. (${_item?.page ? "p." + _item?.page + " " : ""} ${
    //           _item?.section && "sec." + _item?.section
    //         })`) +
    //       ` (${_item.url})` +
    //       "\n");
    //   });
    return `${
      "\n" +
      "Generated by https://EntheoGPT.com Entheo AI chatbot. " +
      "\n\n" +
      `Sources : \n${refers}`
    }`;
  };
  const copy = async () => {
    await navigator.clipboard.writeText(
      `${user?.firstName}: \n` +
        question +
        "\n" +
        "\nEntheoGPT:\n" +
        message +
        "\n" +
        getReferences()
    );
    // alert('Text copied');
  };
  return (
    <>
      {trigger && <ChatOverlaysBot intent={trigger} />}
      <Grid
        container
        justifyContent="start"
        sx={{
          marginTop: "13px",
          marginBottom: "13px",
          paddingRight: { xs: "10px", md: "70px" },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", position: "relative" }}
          alignItems="end"
        >
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img
              alt={botDetails?.botName}
              src={botDetails?.iconUrl}
              className="flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
              style={{ maxWidth: 62, height: 62 }}
            />
          </Box>
          <Box
            sx={{
              marginLeft: {
                xs: "10px",
                md: "26px",
              },
              position: "relative",
              maxWidth: "100%",
            }}
          >
            <Typography
              component="div"
              variant="body1"
              className="break-words"
              sx={{
                backgroundColor:
                  theme?.palette.mode === "light"
                    ? "background.botMessage"
                    : "#202940",
                padding: "18px 38px 18px 26px",
                maxWidth: { md: "835px", xs: "100%" },
                borderRadius: "18px 18px 18px 0px",
                span: {
                  whiteSpace: "inherit !important",
                },
              }}
            >
              {/* <FormattedResponse response={_item.message} /> */}
              {/* // response={parse(
                //   message.substring(0, currentWord).replace(/\n/g, "<br>")
                // )} */}
              {/* eslint eqeqeq: 0 */}
              {isBotResponse && messageIndex == 0 && !showSuggestions ? (
                <>
                  <FormattedResponse
                    response={message.substring(0, currentWord)}
                  />
                </>
              ) : (
                <FormattedResponse response={message} />
                // parse(message.replace(/\n/g, "<br>"))
              )}
              {isBotResponse && messageIndex == 0 && !isComplete && (
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  ...
                </span>
              )}

              {refer && refer?.science?.length + refer?.legal?.length > 0 ? (
                <div className="flex justify-start items-center gap-2 mt-1">
                  <ShowSources
                    questionId={questionId}
                    question={question}
                    message={message}
                    refer={refer}
                  />
                  {/* {tokens && (
                  <a
                    href="#"
                    className={`text-sm font-semibold ${
                      theme?.palette.mode === "light"
                        ? "text-blue-gray-600"
                        : "text-white"
                    }`}
                  >
                    Tokens: [{tokens}]
                  </a>
                  // <Chip
                  //   className="self-center"
                  //   variant="outlined"
                  //   color="primary"
                  //   size="small"
                  //   label={tokens}
                  // />
                )} */}
                  {model && (
                    <Chip
                      className="self-center"
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={textToHeaderCase(model)}
                    />
                  )}
                  {user?.role === "admin" && tokens && (
                    <Chip
                      className="self-center"
                      variant="filled"
                      color="info"
                      size="small"
                      label={`Tokens:${tokens}`}
                    />
                  )}
                </div>
              ) : null}
            </Typography>
            <Box
              className={`triangle-down-bot 
            ${theme?.palette.mode === "dark" && "!border-t-[#202940]"}
            `}
            ></Box>
            <Box
              className="bottom-3 right-2"
              sx={{
                // position: { xs: 'inherit', md: 'absolute' },
                // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                position: "absolute",
                display: "flex",

                // marginLeft: 0,
              }}
            >
              <CopyToClipboard
                text={message.replace(/<[^>]+>/g, "") + `${getReferences()}`}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied");
                }}
              >
                <Box>
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy" onClick={() => copy()}>
                      <ContentCopyIcon sx={{ height: "16px", width: "16px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CopyToClipboard>

              <ResponseFeedback questionId={questionId} feedback={feedback} />
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            mt: "10px",
            width: "50px",
            height: "50px",
          }}
        >
          <img
            alt={botDetails?.botName}
            src={botDetails?.iconUrl}
            className="flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Grid>
    </>
  );
};

export const BotThinking: FC = () => {
  const { botDetails } = useSelector((state: any) => state.bot);
  const [loadingTime, setLoadingTime] = useState(0);
  const theme = useTheme();

  let timer: any;
  useEffect(() => {
    isBotResponse = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setInterval(() => {
      setLoadingTime((value) => value + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const thinkingContent = useMemo(() => {
    if (loadingTime === 0) {
      return "Searching document database…";
    } else if (loadingTime > 0 && loadingTime < 3) {
      return "Reading thousands of papers…";
    } else if (loadingTime >= 3 && loadingTime < 6) {
      return "References retrieved! Augmenting your prompt…";
    } else {
      clearInterval(timer);
      return "Generating augmented response…";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTime]);

  return (
    <Grid
      container
      justifyContent="start"
      sx={{
        marginTop: "13px",
        marginBottom: "13px",
        paddingRight: { xs: "10px", md: "70px" },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: "flex", position: "relative" }}
        alignItems="end"
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            alt={botDetails?.botName}
            src={botDetails?.iconUrl}
            className="flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
            style={{ minWidth: 62, height: 62 }}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { xs: "10px", md: "26px" },
            position: "relative",
            maxWidth: "100%",
          }}
        >
          <Typography
            component="div"
            variant="body1"
            className="break-words"
            sx={{
              backgroundColor:
                theme?.palette.mode === "light"
                  ? "background.botMessage"
                  : "#202940",
              padding: "18px 38px 18px 26px",
              maxWidth: { md: "835px", xs: "100%" },
              borderRadius: "18px 18px 18px 0px",
              span: {
                whiteSpace: "inherit !important",
              },
            }}
          >
            <div className="streamingTestLoader">{thinkingContent}</div>
          </Typography>
          <Box
            className={`triangle-down-bot 
            ${theme?.palette.mode === "dark" && "!border-t-[#202940]"}
            `}
          ></Box>{" "}
        </Box>
      </Grid>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          mt: "10px",
          width: "50px",
          height: "50px",
        }}
      >
        <img
          alt={botDetails?.botName}
          src={botDetails?.iconUrl}
          className="flex bg-white items-center justify-center rounded-full border-2 border-dashed border-token-border-medium"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    </Grid>
  );
};
